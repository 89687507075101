import React, { useState,useEffect} from 'react';
import { useParams } from 'react-router';
import { Row,Col,Card,Button} from 'react-bootstrap';
import '../../Config.js';
import "./HostWindow.css";
import "../HostVideo/HostVideo.css";
import axios from 'axios';
import HostHeader from "../HostHeader/HostHeader";
import Header from "../Header/Header";
import HostVideoState from "../HostVideoState/HostVideoState";
import { useForm } from "react-hook-form";
import Footer from "../Footer/Footer";

const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;

function HostWindow() {
	
  const { id } = useParams();
  const [hostId, sethostId] = useState('');  
  const [hostvalidation,sethostvalidation] = useState(false);  
  const [hostvalidation_errorflag,sethostvalidation_errorflag] = useState(false);
  const [hostvalidation_errormessage,sethostvalidation_errormessage] = useState("");  
  const [hostDataObj,sethostDataObj] = useState("");
  const [sessionvalidation,setsessionvalidation] = useState(false);  
  const {register, handleSubmit, formState: { errors } } = useForm();  
  
  useEffect(() => {	  
	  var responsedata=sessionStorage.getItem("hostdata");	  		
	  setsessionvalidation(true);	
	  if(responsedata != null && responsedata !="") {
		  sethostvalidation(true);	  
		  var hostdata=JSON.parse(responsedata);	  
		  var referenceno=hostdata.referenceno;
		  sethostId(referenceno); 			
		  sethostDataObj(hostdata);		
	  }	  
  },[]);
   
  const onSubmit  = async(data)=> {	
  
		 const formData=new FormData();		        						
		 formData.append("data",JSON.stringify(data));	  		
		 const response = await axios.post(API_URL+'hostvalidation/', formData, {
			 headers: {
				'Content-Type': 'multipart/form-data'
			 }
		 }); 		 
		var responsedata=response.data;    
		if(responsedata.status) { 			
			sethostvalidation(true);
			var hostdata=responsedata.data;
			var referenceno=hostdata.referenceno;
			sethostId(referenceno); 			
			sethostDataObj(hostdata);				
			sessionStorage.setItem("hostdata",JSON.stringify(hostdata));
		}
		else {						
			sethostvalidation_errormessage(responsedata.error);
		}
  }
 
  return (
    <div>		
		{hostvalidation && !hostvalidation_errorflag && (
			 <HostVideoState>
			  <div className="App" style={{ height: "auto", width: "auto"}}>
				<HostHeader hostObj={hostDataObj}/>
				<Footer/>
			  </div>
			</HostVideoState>
		)}
		
		{!sessionvalidation && (			
			<div>                            
                <Header/>
				<div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>				
			</div>
		)}
		
		{!hostvalidation && !hostvalidation_errorflag && sessionvalidation && ( 
            <div>                            
                <Header/>        
					<div style={{marginTop:"8%"}}>
						<Row>
						<Col md='4' className="mgt-10"></Col>
						<Col md='4' className="mgt-10">	
							<form onSubmit={handleSubmit(onSubmit)}>
								<Card>
									<Card.Header className="align-center">
										Login										
									</Card.Header>
									<Card.Body>																											
										<Row>
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="usermail">Usermail</label>
												 </div>											 
												 <div>
													<input type="text" id="usermail" autoComplete='Off'
														className="form-control" 
														aria-invalid={errors.usermail ? "true" : "false"} 
														{...register('usermail', { 
															required: true,
															pattern: {
																value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
																message: "invalid email address"
															}
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.usermail && (
													<span role="alert" className="errormessage">Invalid email address</span> 
												   )}
												  </div>
											</Col>    
											<Col md='12' className="mgt-15"> 
												<div>
													<label className="form-label" htmlFor="password">Password</label>
												 </div>											 
												 <div>
													<input type="password"  id="password" className="form-control" aria-invalid={errors.password ? "true" : "false"} {...register('password', { required: true })} />
												  </div>
												  
												  <div>
												  {errors.password && (
													<span role="alert" className="errormessage">This field is required</span> 
												   )}
												  </div>
											</Col>    											  											
								 		</Row>		
										
										<div className="mgt-10 align-center"><label className='errormessage'>{hostvalidation_errormessage}</label></div>
										
								</Card.Body>
								<Card.Footer>
									<Row>
										<Col md='12' className="mgt-10 align-center"> 
											<input className="btn btn-primary" type="submit" />																			
										</Col>   
									</Row>									
								</Card.Footer>								
							</Card> 
							</form>	
						  </Col>
						  <Col md='4'></Col>
						 </Row>
					</div>
				<Footer/>
            </div>
        )}
    </div>
  );
}
export default HostWindow;
