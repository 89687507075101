import React, { useCallback, useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import HostVideoContext from "../HostVideoState/HostVideoContext";
import { Row, Col, Card, Button } from "react-bootstrap";
import panImage from "../../pan_demo.png";
import "../../Config.js";
import axios from "axios";
import "../HostVideo/HostVideo.css";
import HostVideoState from "../HostVideoState/HostVideoState";
import closebutton from "../../closebutton.png";
import tickbutton from "../../tickbutton.png";
import zoombtn from "../../zoom-in.png";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const API_URL = global.CONFIG_API_URL;
const CAPTURED_IMAGE_WIDTH = global.CAPTURED_IMAGE_WIDTH;
const CAPTURED_IMAGE_HEIGHT = global.CAPTURED_IMAGE_HEIGHT;
const CLIENT_BROWSERS = global.CLIENT_BROWSERS;
const DISCONNECT_BTN = global.DISCONNECT_BTN;
const ESIGN_BTN = global.ESIGN_BTN;

function PAN() {
  const {
    callAccepted,
    step1,
    step2,
    clientName,
    clientPAN,
    client_referenceno,
    clientpanocrstatus,
    userpanImage,
    processPANOCR,
    userpanImageCSS,
    enableRearCamera,
    startVideoRecording,
    recordingstatus,
    hostsidesnapshot,
    clientsidesnapshot,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    stopVideoRecording,
    startClientsideVideoRecording,
    saveocrdata,
    clientBrowserName,
    disconnecthostsocket,
    inita2formesign,
  } = useContext(HostVideoContext);
  const [pan_firstname, setpan_firstname] = useState("");
  const [pan_lastname, setpan_lastname] = useState("");
  const [pan_status, setpan_status] = useState("");
  const [pan_pannumber, setpan_pannumber] = useState("");
  const [pan_pandob, setpan_pandob] = useState("");
  const [panverification, setpanverification] = useState(false);
  const [panverificationstatus, setpanverificationstatus] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [panapierror, setpanapierror] = useState(false);
  const [user_panimage, setuser_panimage] = useState("");
  const [client_browserName, setclient_browserName] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    setuser_panimage(userpanImage);
  }, [userpanImage]);

  useEffect(() => {
    setclient_browserName(clientBrowserName);
  }, [clientBrowserName]);

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);
  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const openLightBox = () => {
    setisOpen(true);
  };

  const closeLightBox = () => {
    setisOpen(false);
  };

  useEffect(async () => {
    if (clientpanocrstatus) {
      const formData = new FormData();
      formData.append("pan", clientPAN);
      formData.append("referenceno", client_referenceno);
      let response = "";
      response = await axios.post(API_URL + "panverification/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response);

      if (response != "") {
        var responsedata = response.data;
        var pandata = responsedata.data;
        if (pandata == "" || pandata == false) {
          setpanapierror(true);
          return false;
        }
        var pan_status = pandata[0].pan_status;

        //Modified on the 21st Mar 2024, to cater to the change in Status values
        let validRegex = /(^|[^I]N?)VALID/;
        let partialRegex = /PARTIAL/;
        let statusUpper = pan_status.toUpperCase();

        //if (pan_status == "VALID") {
        if (validRegex.test(statusUpper) && !statusUpper.includes("INVALID")) {
          setpan_status(pan_status);
          setpan_firstname(pandata[0].first_name);
          setpan_lastname(pandata[0].last_name);
          setpan_pannumber(pandata[0].pan_number);
          setpan_pandob(pandata[0].pan_dob);
          setpanverificationstatus(true);
          var pannname = pandata[0].first_name + " " + pandata[0].last_name;
          if (pannname == clientName) setpanverificationstatus(true);
          setpanverification(true);
        } else {
          setpan_status(pan_status);
          setpanverificationstatus(false);
        }
      }
      setpanverification(true);
    }
  }, [clientpanocrstatus]);

  return (
    <div>
      <Row>
        <Col md="12">
          {callAccepted && (
            <div>
              <Row>
                <Col md="12" className="mgt-10 align-right">
                  {recordingstatus && (
                    <div className="mgt-10">
                      <input
                        type="button"
                        className="btn btn-success float-right"
                        onClick={hostsidesnapshot}
                        value="Host Capture"
                      />

                      {DISCONNECT_BTN && (
                        <input
                          type="button"
                          className="btn btn-success float-right"
                          onClick={disconnecthostsocket}
                          value="Disconnect Socket"
                        />
                      )}

                      {ESIGN_BTN && (
                        <input
                          type="button"
                          className="btn btn-success float-right"
                          onClick={inita2formesign}
                          value="E-SIGNIN"
                        />
                      )}

                      {CLIENT_BROWSERS.indexOf(client_browserName) != 1 && (
                        <input
                          type="button"
                          className="btn btn-danger float-left"
                          onClick={clientsidesnapshot}
                          value="Client Capture"
                        />
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Col>
        <Col md="12">
          {clientpanocrstatus && (
            <div className="nsdl_layer">
              {panapierror && (
                <div>
                  <b>Unable to retrive PAN Data</b>
                </div>
              )}
              <Row>
                <Col md="12" className="mgt-3">
                  <label className="nsdl-header">
                    <b>NSDL Data:</b>
                  </label>
                </Col>

                <Col md="2" className="mgt-3">
                  <label>
                    <b>Status</b>
                  </label>
                </Col>
                <Col md="3" className="mgt-3">
                  : {pan_status}
                </Col>
                <Col md="1" className="mgt-5">
                  {panverificationstatus && (
                    <img src={tickbutton} width="20" height="20" />
                  )}
                  {!panverificationstatus && (
                    <img src={closebutton} width="20" height="20" />
                  )}
                </Col>
                <Col md="2" className="mgt-3">
                  <label>
                    <b>Number</b>
                  </label>
                </Col>
                <Col md="4" className="mgt-3">
                  : {pan_pannumber}
                </Col>
                <Col md="2" className="mgt-5">
                  <label>
                    <b>Name</b>
                  </label>
                </Col>
                <Col md="10" className="mgt-5">
                  : {pan_firstname} {pan_lastname}
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>

      {callAccepted && (
        <div>
          {!recordingstatus && (
            <div className="align-center">
              <div className="mgt-100" style={{ marginRight: "20px" }}>
                <input
                  type="button"
                  className="btn btn-danger btn-recording"
                  onClick={startVideoRecording}
                  value="Host Side Start Recording"
                />{" "}
                <br />
                <br />
                {/*<input type="button" className="btn btn-danger btn-recording" onClick={startClientsideVideoRecording} value="Client Side Start Recording" />*/}
              </div>
            </div>
          )}

          <Row>
            <Col md="12" className="mgt-10 align-center">
              <div id="pan_layer" className="pan_image">
                <canvas
                  width="450"
                  height="900"
                  style={{ display: "none" }}
                ></canvas>
                {userpanImage && (
                  <div>
                    <img
                      className="captured_image"
                      src={userpanImage}
                      onClick={openLightBox}
                      width={CAPTURED_IMAGE_WIDTH}
                      height={CAPTURED_IMAGE_HEIGHT}
                    />
                  </div>
                )}

                {isOpen && (
                  <Lightbox
                    image={userpanImage}
                    onClose={closeLightBox}
                    title="PAN"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
export default PAN;
