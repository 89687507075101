import React, { useState, useContext, useEffect, useRef } from "react";
import { Input, Button, Tooltip, Modal, message } from "antd";
import Phone from "../../assests/phone.gif";
import Teams from "../../assests/teams.mp3";
import * as classes from "./Options.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HostVideoContext from "../HostVideoState/HostVideoContext";
import Hang from "../../assests/hang.svg";


import {
  PhoneOutlined,
} from "@ant-design/icons";

//import { socket } from "../HostVideoState/HostVideoState";

const HostOptions = () => {

  const [idToCall, setIdToCall] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const Audio = useRef();

  const { call, callAccepted, name, callEnded, callUser, leaveCall, answerCall, declineCall,setOtherUser, leaveCall1, 
    clientcallerType } = useContext(HostVideoContext);

  useEffect(() => {

    if (isModalVisible) {
      Audio?.current?.play();
    } else Audio?.current?.pause();
  }, [isModalVisible]);

  const showModal = (showVal) => {
    setIsModalVisible(showVal);
  };

  const handleCancel = () => {

    setIsModalVisible(false);
    leaveCall1();
    window.location.reload();
  };

  useEffect(() => {

    if (call.isReceivingCall && !callAccepted) {
      setIsModalVisible(true);
      setOtherUser(call.from);
    } else setIsModalVisible(false);
  }, [call.isReceivingCall]);
  
  return (
    <div className="options_layer">	
      <div>
        {callAccepted && !callEnded ? (
          <Button variant="contained" onClick={leaveCall}
            className={classes.hangup_btn} tabIndex="0" style={{zIndex:"999999"}} >
            <img src={Hang} alt="hang up" style={{ height: "20px" }} />
            &nbsp; <span className="text_white">Hang up</span>
          </Button>
        ) : (
          <div>
            {clientcallerType && (              
                <Button style={{display:'none'}} type="primary" icon={<PhoneOutlined />}
                  onClick={() => {
                    if (name.length) callUser(idToCall);
                    else message.error("Please enter your name to call!");
                  }}
                  className="Options_hang__2DAHh"
                  tabIndex="0"
                >
                  Call
                </Button>
            )}
          </div>
        )}
      </div>

      {call.isReceivingCall && !callAccepted && (
        <>
          <audio src={Teams} loop ref={Audio} />
          <Modal
            title="Incoming Call"
            visible={isModalVisible}
            onOk={() => showModal(false)}
            onCancel={handleCancel}
            footer={null}
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <h1 style={{fontSize:'18px'}}>
                {call.name} is calling you:{" "}
                <img
                  src={Phone}
                  alt="phone ringing"
                  className={classes.phone}
                  style={{ display: "inline-block" }}
                />
              </h1>
            </div>
            <div className={classes.btnDiv}>
              <Button variant="contained" className={classes.answer} color="#29bb89" icon={<PhoneOutlined />}
                onClick={() => { answerCall(); Audio.current.pause(); }} tabIndex="0">Answer
              </Button>
              <Button variant="contained" className={classes.decline} icon={<PhoneOutlined />}
                onClick={() => { declineCall();setIsModalVisible(false); Audio.current.pause(); }} tabIndex="0">Decline
              </Button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default HostOptions;
