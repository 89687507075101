import React, {useContext,useState,useEffect} from 'react';
import { useParams } from 'react-router';
import HostVideoContext from "../HostVideoState/HostVideoContext";
import { Row,Col,Card,Button} from 'react-bootstrap';
import '../../Config.js';
import axios from 'axios';
import "../HostVideo/HostVideo.css";
import HostVideoState from "../HostVideoState/HostVideoState";

const API_URL=global.CONFIG_API_URL;

function ProcessInstructions() {

    const {host_callerType} = useContext(HostVideoContext);
	const [hostcaller_Type,sethostcallerType] = useState(host_callerType);
	const [processquestions, setprocessquestions] = useState([]);
	
	useEffect( async() => {			
      const response = await axios.get(API_URL+'getprocesssteps/');      
	  var instructionsdata=response.data;
	  var instructions=instructionsdata.data;
	  var instructionsarray=[];
	  for(var a=0;a<instructions.length;a++)
	  	instructionsarray.push(instructions[a].question);
		setprocessquestions(instructionsarray);
	},[hostcaller_Type]);
	
    return(
        <div className="align-left">	
			<b>Instructions :</b>
		   <ul>
		  {
			React.Children.toArray(
			  processquestions.map((item, i) => <li className="mgt-3 fontsize-12">{item}</li>)
			)
		  }
		   </ul>
        </div>
    )
}
export default ProcessInstructions;