import React from "react";
import { HeartTwoTone } from "@ant-design/icons";
import "./Footer.css";

const Footer = () => {
  return (
    <div>  {/*
    <footer className="footer">
		  <strong>Powered By <a href="https://www.DigiKYC.in" target="_blank">DigiKYC</a></strong>	
    </footer> */}
    </div>
  );
};

export default Footer;
