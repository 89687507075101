import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./ClientHeader.css";
import Logo from "../../assests/orient.png";
import { Container, Row, Col } from 'react-bootstrap';
import ClientVideoContext from "../ClientVideoState/ClientVideoContext";
import { socket } from "../ClientVideoState/ClientVideoState";

const ClientHeader = ({orgObj,internetspeed,internetspeedlastupdated}) => {

    const [org_logo,setorg_logo] = useState("");
    const [org_name,setorg_name] = useState("");
    const [org_logo_height,setorg_logo_height] = useState("");

    useEffect(async() => {	  
        if(orgObj) {
            setorg_logo(orgObj.logo);
            setorg_name(orgObj.orgname);
            setorg_logo_height(orgObj.logoheight);
        }
    },[orgObj]);

    return (
      <header className="mainheader">
          <Row>
              <Col md="3" xs="3" className="pdl-10 brandname">DigiKYC</Col>
              <Col md="6" xs="8" className="pdl-10 orgname"><img src={org_logo} height={org_logo_height}/>{org_name}</Col>
              <Col md="3" xs="0" className="pd-10">

                {/*<div className="clientinternetspeed">BandWidth:{internetspeed} MB</div>
                 <div className="clientinternetspeed">BandWidth Updated On:{internetspeedlastupdated}</div>*/}

              </Col>
          </Row>
    </header>);
}
export default ClientHeader;
