import React, {useContext,useState,useEffect} from 'react';
import { useParams } from 'react-router';
import { Row,Col,Card,Button} from 'react-bootstrap';
import ClientVideoContext from "../ClientVideoState/ClientVideoContext";
import ClientVideoState from "../ClientVideoState/ClientVideoState";
import "../ClientVideo/ClientVideo.css";
import { socket } from "../ClientVideoState/ClientVideoState";
import axios from 'axios';
import "./A2Form.css";
import { useForm } from "react-hook-form";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hang from "../../assests/hang.svg";

const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
const WEBAPP_URL=global.CONFIG_WEBAPP_URL;
const A2FORM_DOWNLOAD=global.A2FORM_DOWNLOAD;
const A2FORMSIGNIN=global.A2FORMSIGNIN;
const zoopredirecturl=global.zoopredirecturl;
const SIGNINCOMPLETEMESSAGE=global.SIGNINCOMPLETEMESSAGE;

const POPUPBROWSER=global.POPUPBROWSER;
const SAMEBROWSER=global.SAMEBROWSER;

function A2Form() {
	
  const {client_referenceno,otpsuccess,leaveCall} = useContext(ClientVideoContext);
  const {register, handleSubmit, formState: { errors } } = useForm();
  const [a2formdatacheck, seta2formdatacheck] = useState(false); 
  const [seta2formstatus, setseta2formstatus] = useState(false);  
  const [a2formdata,seta2formdata] = useState("");
  const [a2formhtml,seta2formhtml] = useState("");
  const [faceimage,setfaceimage] = useState("");
  const [otpvalidationflag,setotpvalidationflag] = useState(false);
  const [esigninflag,setesigninflag] = useState(false);
  const [pdfurl,setpdfurl] = useState("");
  const [zoopverificationurl,setzoopverificationurl] = useState("");
  
  useEffect( async() => {
	  
	var clienta2formdataInrtervalcount=0;
	var clienta2formdataInrterval = setInterval(async() => {

		if(socket.connected) {

			clearInterval(clienta2formdataInrterval);
			const formData=new FormData();		        						
			formData.append("referenceno",client_referenceno);	        
			const response = await axios.post(API_URL+'clienta2formdata/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});       
			
			var responsedata=response.data; 
			seta2formdatacheck(true);
			if(responsedata.status) {		
				var formdata=responsedata.data;
				seta2formdata(formdata);
				var forma2html=responsedata.a2formhtml;
				seta2formhtml(forma2html);
			}
			else setseta2formstatus(false);
		}
		else {
			clienta2formdataInrtervalcount++;
			if(clienta2formdataInrtervalcount == 5) {
			  clearInterval(clienta2formdataInrterval);
			  toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
			}
		}

	},500);
	
  },[]);
  
  
	useEffect( async() => {
	  
	  if(a2formdata !="") {		  
		  setseta2formstatus(true);	
		  let fimage=WEBAPP_URL+a2formdata.facephoto;
		  setfaceimage(fimage);
	  }
	  
	},[a2formdata]);

	useEffect( async() => {
		if(otpvalidationflag) {		  
			otpsuccess(true);
		}
	  },[otpvalidationflag]);

	  
	const onSubmit  = async(data)=> {	

		var a2formsubmitInrtervalcount=0;
		var a2formsubmitInrterval = setInterval(async() => {

			if(socket.connected) {
				
				clearInterval(a2formsubmitInrterval);
				const formData=new FormData();		        						
				formData.append("data",JSON.stringify(data));
				formData.append("referenceno",client_referenceno);	
				const response = await axios.post(API_URL+'savea2form/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}); 
				
				var responsedata=response.data;          
				if(responsedata.status) {
					var formdata=responsedata.data;
					seta2formdata(formdata);
					var forma2html=responsedata.a2formhtml;
					seta2formhtml(forma2html);
				}
				else { 
					toast.error(responsedata.message,{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}
			else {

				a2formsubmitInrtervalcount++;
				if(a2formsubmitInrtervalcount == 5) {
				  clearInterval(a2formsubmitInrterval);
				  toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}

		},500);
	}
  
	const handleOTPSubmit = async(data)=> {	
		
		var a2formotpsubmitInrtervalcount=0;
		var a2formotpsubmitInrterval = setInterval(async() => {

			if(socket.connected) {

				clearInterval(a2formotpsubmitInrterval);
				const formData=new FormData();		        						
				formData.append("data",JSON.stringify(data));
				formData.append("referenceno",client_referenceno);	
				const response = await axios.post(API_URL+'verifyotp/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}); 
				var responsedata=response.data;          
				if(responsedata.status) {
					setpdfurl(responsedata.pdfurl);
					toast.success("OTP has been verified succcessfully.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
					setotpvalidationflag(true);
				}
				else { 
					toast.error("OTP is not Matching.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}
			else {
				a2formotpsubmitInrtervalcount++;
				if(a2formotpsubmitInrtervalcount == 5) {
				  clearInterval(a2formotpsubmitInrterval);
				  toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}

		},500);
	}

	const resendOTP =async() => {
	  
		var a2formresendInrtervalcount=0;
		var a2formresendInrterval = setInterval(async() => {

			if(socket.connected) {

				clearInterval(a2formresendInrterval);
				const formData=new FormData();		        						
				formData.append("referenceno",client_referenceno);	
				const response = await axios.post(API_URL+'resendotp/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}); 
				
				var responsedata=response.data;          
				if(responsedata.status) {
					toast.info("OTP has been sent succcessfully.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});
				}
				else { 			
					toast.error(responsedata.message,{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});
				}
			}
			else {
				a2formresendInrtervalcount++;
				if(a2formresendInrtervalcount == 5) {
				  clearInterval(a2formresendInrterval);
				  toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}

		},500);
  }
  
  const callinita2formesignin = async()=> {	

		var a2formesigninInrtervalcount=0;
		var a2formesigninInrterval = setInterval(async() => {

			if(socket.connected) {
				
				clearInterval(a2formesigninInrterval);
				const formData=new FormData();		        						
				formData.append("referenceno",client_referenceno);	
				const response = await axios.post(API_URL+'inita2formesignin/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}); 

				var jsonData=response.data;
				var apidata=jsonData.apidata;
				var apidataObj=JSON.parse(apidata);
				if(apidataObj.success) {	
						
					var request_id=apidataObj.request_id;					
					var stringobj ={};
					stringobj['clientid']=client_referenceno;
					stringobj['requestid']=request_id;
					var encode = btoa(unescape(encodeURIComponent(JSON.stringify(stringobj))));
					let zoopurl=zoopredirecturl+"?data="+encode;
					if(POPUPBROWSER) {
							
						setTimeout(function () {
							try {

								window.open(
									zoopurl, 'VKYC', 'toolbar=1, scrollbars=1, location=1,statusbar=0, menubar=1'
								).focus();
															
							} catch (e) {
								toast.error("Pop-up Blocker is enabled! Please disable your pop-up blocker.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});							
							}
						}, 10);
					}
						
					if(SAMEBROWSER) {						
						setzoopverificationurl(zoopurl);
					}
					setotpvalidationflag(true);
				}
				else {
					var response_message=apidataObj.response_message;	
					var metadata=apidataObj.metadata;
					var reason_message=metadata.reason_message;
					toast.error(response_message+"."+reason_message,{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});							
				}
			}
			else {

				a2formesigninInrtervalcount++;
				if(a2formesigninInrtervalcount == 5) {
				  clearInterval(a2formesigninInrterval);
				  toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}

		},500);
	}

  return (	
	<div className="a2formlayer">  

		{!a2formdatacheck && (
			<div className="bouncing-loader">
				<div></div> 
			</div>
		)}
		
		{a2formdatacheck && !seta2formstatus && !otpvalidationflag && (
		
					<div style={{minHeight:"550px"}}>
							<div className="host_contentarea" style={{marginTop:"0.1%"}}>
						<Row>
						<Col md='4'></Col>
						<Col md='4'>	
							<form onSubmit={handleSubmit(onSubmit)}>
								<Card>
									<Card.Header className="align-center">
										A2Form										
									</Card.Header>
									<Card.Body>																											
										<Row>	
										
											<div className="mgt-15"> <b>Passport Details</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="passport_issueplace">Passport Issue Place</label>
												 </div>											 
												 <div>
													<input type="text" id="passport_issueplace" 
														className="form-control" 
														aria-invalid={errors.passport_issueplace ? "true" : "false"} 
														{...register('passport_issueplace', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.passport_issueplace && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="passport_issuedate">Passport Issue Date</label>
												 </div>											 
												 <div>
													<input type="text" id="passport_issuedate" 
														className="form-control" 
														aria-invalid={errors.passport_issuedate ? "true" : "false"} 
														{...register('passport_issuedate', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.passport_issuedate && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="passport_expirydate">Passport Expiry Date</label>
												 </div>											 
												 <div>
													<input type="text" id="passport_expirydate" 
														className="form-control" 
														aria-invalid={errors.passport_expirydate ? "true" : "false"} 
														{...register('passport_expirydate', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.passport_expirydate && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<div className="mgt-15"> <b>Passport Details</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="visareferenceno">Visa / Ticket Reference No</label>
												 </div>											 
												 <div>
													<input type="text" id="visareferenceno" 
														className="form-control" 
														aria-invalid={errors.visareferenceno ? "true" : "false"} 
														{...register('visareferenceno', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.visareferenceno && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="dateofvisit">Date of Visit</label>
												 </div>											 
												 <div>
													<input type="text" id="dateofvisit" 
														className="form-control" 
														aria-invalid={errors.dateofvisit ? "true" : "false"} 
														{...register('dateofvisit', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.dateofvisit && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<div className="mgt-15"> <b>Payment Details</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="modeofpayment">Mode of Payment</label>
												 </div>											 
												 <div>
													<input type="text" id="modeofpayment" 
														className="form-control" 
														aria-invalid={errors.modeofpayment ? "true" : "false"} 
														{...register('modeofpayment', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.modeofpayment && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="drawnonbank">Drawn On (Bank Name and Branch)</label>
												 </div>											 
												 <div>
													<input type="text" id="drawnonbank" 
														className="form-control" 
														aria-invalid={errors.drawnonbank ? "true" : "false"} 
														{...register('drawnonbank', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.drawnonbank && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="inrtotal">INR Total Value</label>
												 </div>											 
												 <div>
													<input type="text" id="inrtotal" 
														className="form-control" 
														aria-invalid={errors.inrtotal ? "true" : "false"} 
														{...register('inrtotal', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.inrtotal && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="currencytotal">Foreign currency total value</label>
												 </div>											 
												 <div>
													<input type="text" id="currencytotal" 
														className="form-control" 
														aria-invalid={errors.currencytotal ? "true" : "false"} 
														{...register('currencytotal', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.currencytotal && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<div className="mgt-15"> <b> Foreign Exchange Requirement </b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="countrytobevisited">Country / Countries to be visited</label>
												 </div>											 
												 <div>
													<input type="text" id="countrytobevisited" 
														className="form-control" 
														aria-invalid={errors.countrytobevisited ? "true" : "false"} 
														{...register('countrytobevisited', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.countrytobevisited && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
										
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="visitpurpose">Purpose of Visit</label>
												 </div>											 
												 <div>
													<input type="text" id="visitpurpose" 
														className="form-control" 
														aria-invalid={errors.visitpurpose ? "true" : "false"} 
														{...register('visitpurpose', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.visitpurpose && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 

											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="totalexchange">Total Foreign Exchange Required</label>
												 </div>											 
												 <div>
													<input type="text" id="totalexchange" 
														className="form-control" 
														aria-invalid={errors.totalexchange ? "true" : "false"} 
														{...register('totalexchange', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.totalexchange && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<div className="mgt-15"> <b>Foreign Exchange Issued</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="currency">Foreign currency (Specific Currency)</label>
												 </div>											 
												 <div>
													<input type="text" id="currency" 
														className="form-control" 
														aria-invalid={errors.currency ? "true" : "false"} 
														{...register('currency', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.currency && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="currencynotes">Foreign Currency Notes</label>
												 </div>											 
												 <div>
													<input type="text" id="currencynotes" 
														className="form-control" 
														aria-invalid={errors.currencynotes ? "true" : "false"} 
														{...register('currencynotes', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.currencynotes && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<div className="mgt-15"> <b>Demand Draft</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="benficiaryname">Beneficiary&#39;s Name</label>
												 </div>											 
												 <div>
													<input type="text" id="benficiaryname" 
														className="form-control" 
														aria-invalid={errors.benficiaryname ? "true" : "false"} 
														{...register('benficiaryname', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.benficiaryname && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="benficiaryaddress">Beneficiary&#39;s address</label>
												 </div>											 
												 <div>
													<input type="text" id="benficiaryaddress" 
														className="form-control" 
														aria-invalid={errors.benficiaryaddress ? "true" : "false"} 
														{...register('benficiaryaddress', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.benficiaryaddress && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<div className="mgt-15"> <b>TTs (wire Transfer)</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="tts_benficiaryname">Beneficiary&#39;s Name</label>
												 </div>											 
												 <div>
													<input type="text" id="tts_benficiaryname" 
														className="form-control" 
														aria-invalid={errors.tts_benficiaryname ? "true" : "false"} 
														{...register('tts_benficiaryname', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.tts_benficiaryname && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="tts_beneficiaryaddress">Beneficiary&#39;s Address</label>
												 </div>											 
												 <div>
													<input type="text" id="tts_beneficiaryaddress" 
														className="form-control" 
														aria-invalid={errors.tts_beneficiaryaddress ? "true" : "false"} 
														{...register('tts_beneficiaryaddress', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.tts_beneficiaryaddress && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="bankname">Name of the Bank</label>
												 </div>											 
												 <div>
													<input type="text" id="bankname" 
														className="form-control" 
														aria-invalid={errors.bankname ? "true" : "false"} 
														{...register('bankname', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.bankname && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="bankaddress">Address of the Bank</label>
												 </div>											 
												 <div>
													<input type="text" id="bankaddress" 
														className="form-control" 
														aria-invalid={errors.bankaddress ? "true" : "false"} 
														{...register('bankaddress', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.bankaddress && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="prepaidcard">Prepaid Cards</label>
												 </div>											 
												 <div>
													<input type="text" id="prepaidcard" 
														className="form-control" 
														aria-invalid={errors.prepaidcard ? "true" : "false"} 
														{...register('prepaidcard', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.prepaidcard && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="travellerscheque">Issue travellers cheques</label>
												 </div>											 
												 <div>
													<input type="text" id="travellerscheque" 
														className="form-control" 
														aria-invalid={errors.travellerscheque ? "true" : "false"} 
														{...register('travellerscheque', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.travellerscheque && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<div className="mgt-15"> <b>Details of Remittances made/transactions effected under the Liberalized Remittance scheme</b></div>
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="rem_date">Remittances Date</label>
												 </div>											 
												 <div>
													<input type="text" id="rem_date" 
														className="form-control" 
														aria-invalid={errors.rem_date ? "true" : "false"} 
														{...register('rem_date', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.rem_date && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="rem_amount">Remittances Amount</label>
												 </div>											 
												 <div>
													<input type="text" id="rem_amount" 
														className="form-control" 
														aria-invalid={errors.rem_amount ? "true" : "false"} 
														{...register('rem_amount', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.rem_amount && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
											<Col md='12' className="mgt-10"> 
												<div>
													<label className="form-label" htmlFor="rem_account">Name and address of AD branch</label>
												 </div>											 
												 <div>
													<input type="text" id="rem_account" 
														className="form-control" 
														aria-invalid={errors.rem_account ? "true" : "false"} 
														{...register('rem_account', { 
															required: true
														})} 
												   />
												  </div>
												  
												  <div>
												  {errors.rem_account && (
													<span role="alert" className="errormessage">Please enter correct value</span> 
												   )}
												  </div>
											</Col> 
											
								 		</Row>		
										<div className="mgt-10 align-center"><label className='errormessage'></label></div>
									</Card.Body>
								<Card.Footer>
									<Row>
										<Col md='12' className="mgt-10 align-center"> 
											<input className="btn btn-primary" type="submit" />																			
										</Col>   
									</Row>									
								</Card.Footer>								
							</Card> 
							</form>	
							
						  </Col>
						  <Col md='4'></Col>
						 </Row>
					</div>
				</div>
		)}

		
		{a2formdatacheck && seta2formstatus && !otpvalidationflag && (	
			<div style={{minHeight:"600px"}}>			
				<Card>
					<Card.Header className="card_header">
						Form A2										
					</Card.Header>
					<Card.Body className='card_body'>		
						<div    style={{'padding':'10px','max-height':'550px','overflow-y':'scroll'}} dangerouslySetInnerHTML={{ __html:a2formhtml}} />
					</Card.Body>
					<Card.Footer>

						{A2FORMSIGNIN == "OTP" && (

							<form onSubmit={handleSubmit(handleOTPSubmit)}>		
								<Row>							
									<Col md='12' className="mgt-15"> 
										<div className="align-center">			
											<img src={faceimage} width="120" height="100" />
										</div>

										<div><label className="form-label" htmlFor="otp">Please Enter the OTP</label></div>											 
										<div><input type="text"  id="otp" className="form-control" aria-invalid={errors.otpno ? "true" : "false"} {...register('otp', { required: true })} /></div>												 
										<div>
											{errors.otp && (
												<span role="alert" className="errormessage">Please enter valid OTP</span> 
											)}
										</div>
									</Col>  
									
									<Col md='12' className="mgt-10 mgb-10 align-center"> 	
										<a href="javascript:;" onClick={resendOTP}>Resend OTP</a>
									</Col>  
										
										
									<Col md='12' className="mgt-10 align-center"> 
										<input className="btn btn-primary"  type="submit" />																			
									</Col> 							
								</Row>
							</form>	
						)}
						
						{A2FORMSIGNIN == "ESIGN" && (
							<div>
								<Row>
									<Col md='12' className="mgt-10 align-center" style={{'margin-bottom':'30px'}}> 
									<input type="button" className="btn btn-primary" onClick={callinita2formesignin} value="Verify & E-Sign" />																			
									</Col> 							
								</Row>
							</div>
						)}
					</Card.Footer>					
				</Card>				
			</div>
		)}		

		{otpvalidationflag && A2FORMSIGNIN == "OTP" && (		
			<div className="pd-20">
				<Card>
					<Card.Header>Notification</Card.Header>
					<Card.Body>
						<Row>							
							<Col md='12' className="mgt-10 align-center"> 
								<label className='form-label'>{SIGNINCOMPLETEMESSAGE}</label>
							</Col>    

							{A2FORM_DOWNLOAD && (
								<Col md='12' className="mgt-10 align-center"> 
									<a href={pdfurl}  target="_blank" className="btn btn-primary">Download A2Form</a>    
								</Col>
							)}
						</Row>
						<Row>							
							<Col md='12' className="mgt-10 align-center"> 
							<Button
								variant="contained"
								onClick={leaveCall}		
								className="clienthangupbtn"						
								tabIndex="0">				
								<img src={Hang} alt="hang up" style={{height:"15px"}}/>&nbsp; Hang up			
							</Button>  
							</Col>    
						</Row>						
					</Card.Body>
				</Card> 
			</div>
		)}	

		{otpvalidationflag && A2FORMSIGNIN == "ESIGN" && (		
			<div className="pd-20">
				<Card>
					<Card.Header>A2Form ESIGNIN</Card.Header>
					<Card.Body>
						<Row>							
							<Col md='12' className="mgt-10 align-center"> 
								<iframe src={zoopverificationurl} width="100%" height="600px"></iframe>
							</Col>    
						</Row>

						<Row>							
							<Col md='12' className="mgt-10 align-center"> 
							<Button
								variant="contained"
								onClick={leaveCall}		
								className="clienthangupbtn"						
								tabIndex="0">				
								<img src={Hang} alt="hang up" style={{height:"15px"}}/>&nbsp; Hang up			
							</Button>  
							</Col>    
						</Row>

					</Card.Body>
				</Card> 
			</div>
		)}
	</div>
  );
}
export default A2Form;
