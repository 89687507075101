import React, { useContext,Fragment,useEffect,useState } from 'react';
import Message from './Message';
import Progress from './Progress';
import $ from "jquery";
import HostVideoContext from "../components/HostVideoState/HostVideoContext";
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import * as faceapi from 'face-api.js';
import Switch from "react-switch";
import closebutton from "../closebutton.png";
import tickbutton from "../tickbutton.png";
import usericon from "../usericon.jpg";
import "../components/HostVideo/HostVideo.css";
import '../Config.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./AadharData.css";
import "./FileUpload.css";
import { FaceMatcher } from 'face-api.js';

const API_URL=global.CONFIG_API_URL;
const AADHAR_CAPTURE=global.AADHAR_CAPTURE;
const FACE_CAPTURE=global.FACE_CAPTURE;
const FACEMATCH_DISTANCE=global.FACEMATCH_DISTANCE;
const DISCONNECT_BTN=global.DISCONNECT_BTN;

const AadharData = () => {

  const {aadharUploadStatus,setCapturedFaceImage,clientName,setManual_FaceMatching,client_referenceno,otherUser,captureclientface,face_Image,changeoverlaystatus,changeloaderoverlaytext,disconnecthostsocket} = useContext(HostVideoContext);    
  const [aadhar_name, setaadhar_name] = useState("");
  const [aadhar_gender, setaadhar_gender] = useState("");
  const [aadhar_dob, setaadhar_dob] = useState("");
  const [aadhar_photo, setaadhar_photo] = useState("");
  const [aadhar_address, setaadhar_address] = useState("");
  const [faceImage, setfaceImage] = useState("");
  const [usernamematching, setusernamematching] = useState(false);
  const [facematching, setfacematching] = useState(false);
  const [manualfacematching, setmanualfacematching] = useState(false);
  const [capturedface, setcapturedface] = useState("");

  useEffect( async() => {

    if(aadharUploadStatus) {

    const formData=new FormData();		        						
    formData.append("referenceno",client_referenceno);
    const response = await axios.post(API_URL+'clientaadhardata/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });   
	 
      var responsedata=response.data;
      var datarow=responsedata.data;  
      var aadhar_name=datarow.aadhar_name;
      var aadhar_gender=datarow.aadhar_gender;
      var aadhar_email=datarow.aadhar_email;
      var aadhar_mobile=datarow.aadhar_mobile;
      var aadhar_dob=datarow.aadhar_dob;
      var aadhar_photo="data:image/png;base64,"+datarow.aadhar_photo;
      var aadhar_address=datarow.aadhar_address;
      var facephoto=datarow.facephoto;
      setaadhar_name(aadhar_name);
      setaadhar_gender(aadhar_gender);
      setaadhar_dob(aadhar_dob);
      setaadhar_photo(aadhar_photo);
      setaadhar_address(aadhar_address);	 
      setcapturedface(facephoto); 

      if(FACE_CAPTURE ==2) {
        setTimeout(() => {detectFace(facephoto); },3000);
      }
      
    }
  }, [aadharUploadStatus]);

  useEffect(()=> {
	  setCapturedFaceImage(faceImage);
  },[faceImage]);
  
  useEffect(()=> {
    if(face_Image) {
      setcapturedface(face_Image);
      if(aadharUploadStatus) {setTimeout(() => {detectFace(face_Image); },3000); }
    }
  },[face_Image]);
  
  useEffect(()=> {
	  setManual_FaceMatching(manualfacematching);
  },[manualfacematching]);
  
  useEffect(()=> {
	  
	  if(aadhar_name.trim() != "" && aadhar_name.trim() == clientName.trim())
		  setusernamematching(true);
	  
	  if(aadhar_name.trim() != "" && aadhar_name.trim() != clientName.trim())
		  setusernamematching(false);
	  
  },[aadhar_name,clientName]);

const captureFace = async() => {

    if(FACE_CAPTURE == 2) {
      console.log("FACE_CAPTURE");

      captureclientface();
      
    }
    else {   
      
      var localvideo=document.getElementById('localVideo');
      if ( localvideo === null ) return;		
      var face_canvas=document.querySelector("#face_canvas_layer canvas");
      var face_ctx = face_canvas.getContext('2d');
      var videoWidth=$("#localVideo").width();
      var videoHeight=$("#localVideo").height();

      var imgWidth=videoWidth;
      var imgHeight=videoHeight;

        face_canvas.width = videoWidth;
        face_canvas.height = videoHeight;	   
        face_ctx.clearRect(0,0,videoWidth,videoHeight);			
        await face_ctx.drawImage(localvideo,0,0,videoWidth,videoHeight, 0,0,imgWidth,imgHeight);
        setcapturedface(face_canvas.toDataURL());		
        detectFace(face_canvas.toDataURL());

    }

  }

  const detectFace = async(facephoto) => {

    changeloaderoverlaytext("Detecting the Face");
    changeoverlaystatus(true);

    let base_image = new Image();
    base_image.src =facephoto;
    base_image.onload =  async() => {
      
        const detections = await faceapi.detectAllFaces(base_image,new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors();          
        changeoverlaystatus(false);
        if(detections.length < 1) {
          alert("Face is not detected.Please recapture the face.");
          return;		
        }	
        await extractFaceFromBox(base_image, detections[0].detection.box);
        var aadharImage=document.getElementById('aadharImage').src;
        let aadhar_image = new Image();
        aadhar_image.src =document.getElementById('aadharImage').src;
        aadhar_image.onload =  async() => {

          const aadhar_detections = await faceapi
          .detectSingleFace(aadhar_image)
          .withFaceLandmarks()
          .withFaceDescriptor();

          if(aadhar_detections) {
            const faceMatcher = new faceapi.FaceMatcher(detections);
            const bestMatch = faceMatcher.findBestMatch(aadhar_detections.descriptor);
            let bestmatchdistance=(1-parseFloat(Math.round(bestMatch.distance * 100) / 100));
            console.log("bestmatchdistance==="+bestmatchdistance);
            if(bestmatchdistance > FACEMATCH_DISTANCE){
              setfacematching(true);
              toast.success("Detected face matched with Aadhar photo.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
            }
            else {
              toast.error("Detected face is not matching with Aadhar photo.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
            }
          }
        }
    };
    
  /*  var aadharImage = document.getElementById('aadharImage');
    var outputImage=document.getElementById('outputImage');

    const detections1 = await faceapi.detectAllFaces(outputImage).withFaceLandmarks().withFaceDescriptors();
    const detections_aadhar = await faceapi.detectSingleFace(aadharImage).withFaceLandmarks().withFaceDescriptor();
    console.log(detections1);
    console.log(detections_aadhar);
    /*
    const faceMatcher = new faceapi.FaceMatcher(detections1);
		if(detections1 != "" && detections1 !=undefined && detections_aadhar !="" && detections_aadhar != undefined) {
             const bestMatch = faceMatcher.findBestMatch(detections_aadhar.descriptor)
			  //const distance = faceapi.euclideanDistance(detections1.descriptor, detections_aadhar.descriptor);
              console.log("======best match===");
			  console.log(bestMatch)			 	  
		}		 
		setfacematching(true);
    */

       async function extractFaceFromBox(inputImage, box) { 
      
          const regionsToExtract = [
              new faceapi.Rect(box.x - 10 , box.y - 30 , box.width + 20 , box.height + 40)
          ]
                      
          let faceImages = await faceapi.extractFaces(inputImage, regionsToExtract)
          if(faceImages.length == 0) {
              console.log('Face not found')
          }
          else {
			        var outputImage = document.getElementById('outputImage');
              faceImages.forEach(cnv => {      					
                  outputImage.src =cnv.toDataURL();    
				          setfaceImage(cnv.toDataURL());
                  uploadPhoto(cnv.toDataURL());
              })
          }   
      }
  }

  const uploadPhoto = async(imagedata) => {	
        

        const formData=new FormData();								
        formData.append("referenceno",client_referenceno);			  
        formData.append("type","face");
        formData.append("imagedata",imagedata);
        try {
            await axios.post(API_URL+'savephoto/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
            }).then((response) => {
              var responsedata=response.data;  				
              if(responsedata.status) {		
                toast.success("Face captured successfully.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
              }
              else { 
                  alert(responsedata.message);
              }		   
            }).catch(err => {			

            });  
          
      } catch (err) {
        if (err.response.status === 500) { console.log('There was a problem with the server');} 
        else { console.log(err.response.data.msg);}
      }
    


   }

  const handlSwitchChange=(checked)=> {
	  setmanualfacematching(checked);
  }

  return (
    <div>

    {DISCONNECT_BTN &&(
		  <input type="button" className="btn btn-success float-right" onClick={disconnecthostsocket} value="Disconnect Socket" />
		)}

      <canvas width = "200" id="facecanvas" height = "200" style={{display:'none'}}></canvas>            
      {aadharUploadStatus && (
          <div>
              <Row>
                <Col md='3' className="mgt-10"> 
                    <label className='form-label'>Name</label>
                </Col>              
                <Col md='9' className="mgt-10"> 
                    : {aadhar_name}
                    {usernamematching && (
                      <img src={tickbutton} width="20" className="mgl-20" height="20"/>
                    )}                    
                    {!usernamematching && (						
                      <img src={closebutton} width="20" className="mgl-20" height="20"/>
                    )}
                </Col>  
            </Row>
			
			<Row>
				<Col md='3' className="mgt-10"> 
                    <label className='form-label'>Gender</label>
                </Col>              
                <Col md='9' className="mgt-10"> 
                    : {aadhar_gender}
                </Col>    
			</Row>
			
            <Row>
                <Col md='3' className="mgt-10"> 
                    <label className='form-label'>Address </label>
                </Col>              
                <Col md='9' className="mgt-10"> 
                   : {aadhar_address}
                </Col>                                      
            </Row>
            <Row className="mgt-20">

               <Col md="4">
                  <div className="align-center">
                    <label><b>Captured Photo</b></label>
                    <div id='face_canvas_layer'>							
                      <canvas width = "450"  height = "900" style={{display:'none'}}></canvas> 
                      <div><img src={capturedface} id="faceimage" width="120" height='130'/></div>   
                    </div>
                  </div>
                </Col>  

                <Col md="4">
                    <div className="align-center">
                    <label><b>Extracted Photo</b></label>
                      <div><img src={usericon} id="outputImage" width="120" height='130'/></div>                  
                      <div><input type="button" className="btn btn-danger capturefacebtn" value="Re-Capture Face" onClick={captureFace} /></div>
                    </div>
                </Col>

                <Col md='4'> 
                  <div  className="align-center">
                    <label><b>Aadhar Photo</b></label>
                      <img src={aadhar_photo} id="aadharImage" width="120" height='130'/>
                  </div>
                </Col>                                                                
            </Row>	

        <Row className="mgt-20">			 
			    <Col md="4" className="align-left">	
					<span className="mgt-5 font-10" >System Face Matching Verification</span>
			   </Col>
         <Col md="2">			
				  <label>					
				  	<Switch  checked={facematching} />
				  </label>
			   </Col>
			    <Col md="4" className="align-left">	
					<span className="mgt-5 font-10" >Manually Face Matching Verification</span>
			   </Col>
         <Col md="2">			
				  <label>					
				  	<Switch onChange={handlSwitchChange} checked={manualfacematching} />
				  </label>
			   </Col>
			  </Row>

          </div>
        )}
    </div>
  );
};

export default AadharData;
