import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router';
import { Row,Col,Card,Button} from 'react-bootstrap';
import '../../Config.js';
import axios from 'axios';
import "./ClientWindow.css";
import "../ClientVideo/ClientVideo.css";
import ClientVideo from "../ClientVideo/ClientVideo";
import ClientVideoState from "../ClientVideoState/ClientVideoState";
import ClientOptions from "../ClientOptions/ClientOptions";
import ClientHeader from "../ClientHeader/ClientHeader";
import Footer from "../Footer/Footer";
import Logger from "../Logger/Logger";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var latitude='',longitude;
const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;

function ClientWindow() {

  const { id } = useParams();  
  const [clientId, setclientId] = useState(''); 
  const [clientdataflag, setclientdataflag] = useState(false);  
  const [clientdata_errorflag, setclientdataerrorflag] = useState(false); 

  const [clientDataObj,setclientDataObj] = useState("");
  const [firstname,setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [pan, setpan] = useState("");
  const [aadhar, setaadhar] = useState("");
  const [passport, setpassport] = useState("");
  const [address, setaddress] = useState("");
  const [makecallflag, setmakecallflag] = useState(false); 
  const [locationflag, setlocationflag] = useState(true); 
  const [clientLatitude, setClientlatitude] = useState("");
  const [clientLongitude, setClientlongitude] = useState("");
  const [clientdata_errormessage,setclientdata_errormessage]=useState(""); 
  const [orgdata, setorgdata] = useState({});
  const [checklistflag, setchecklistflag] = useState(false);
  const [checklist, setchecklist] = useState("");
  const [checklistcounter, setchecklistcounter] = useState(0);
  const [locstate, setlocstate] = useState("");

  useEffect( async() => {

    var decodeddata = decodeURIComponent(escape(window.atob(id)));
    var jsondata=JSON.parse(decodeddata);
    var clienid=jsondata.id;
    var latitude=jsondata.latitude;
    var longitude=jsondata.longitude;
    
    setclientId(clienid);
    setClientlatitude(latitude);
    setClientlongitude(longitude);

    try {

        const formData=new FormData();		        						
        formData.append("referenceno",clienid);	    
        const response = await axios.post(API_URL+'clientbasicdetails/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });    
        
        var responsedata=response.data;  
        if(responsedata.status) {
            
            var rowdata=responsedata.data;
            setclientdataflag(true);
            setfirstname(rowdata.firstname);
            setlastname(rowdata.lastname);
            setemail(rowdata.email);
            setmobile(rowdata.mobile);
            setpan(rowdata.pan);
            setaadhar(rowdata.aadhar);
            setpassport(rowdata.passport);
            setaddress(rowdata.address);

            var obj={};
            obj['name']=rowdata.firstname+" "+rowdata.lastname;
            obj['email']=rowdata.email;
            obj['pan']=rowdata.pan;
            obj['aadhar']=rowdata.aadhar;
            obj['latitude']=latitude;
            obj['longitude']=longitude;
            obj['referenceno']=clienid;
            obj['orgid']=rowdata.orgid;
            obj['orgname']=rowdata.orgname;
            obj['logo']=rowdata.logo;
            obj['logoheight']=rowdata.logoheight;
            setclientDataObj(obj);
            var orgdata={};
            orgdata['orgid']=rowdata.orgid;
            orgdata['orgname']=rowdata.orgname;
            orgdata['logo']=rowdata.logo;
            orgdata['logoheight']=rowdata.logoheight;
            setorgdata(orgdata);
            var checklist=responsedata.checklist;
        }
        else {
            setclientdataerrorflag(true);		
            setclientdata_errormessage(responsedata.error);
        }	
    } catch (error) {
        console.log(error.response); // this is the main part. Use the response property from the error object
       return error.response;
    }

  },[]);

  const initCall = () => {

    if(checklist.length == checklistcounter)
        setmakecallflag(true)
    else {
        alert("Please make sure all the mandatory documents are available.");
    }
    
  };

  const handleCheckboxChange=(e)=>{

    let isChecked = e.target.checked;
    if(isChecked) 
        setchecklistcounter(prevActiveStep => prevActiveStep+1);
    if(!isChecked && checklistcounter > 0) 
        setchecklistcounter(prevActiveStep => prevActiveStep-1);
  }


  return (
    <div>	   

        {makecallflag &&  (
            <ClientVideoState>
			    <div className="App" style={{height:"100%",width:"100%",minHeight:"350px"}}>                       
					<ClientVideo clientDataObj={clientDataObj}/>                                            
				</div>
            </ClientVideoState>
        )}
		
        {!clientdataflag && clientdata_errorflag && (
            <div>
                <ClientHeader orgObj={orgdata}/>    
				<div className="pd-20">
					<Card>
						<Card.Header>Error reading data</Card.Header>
						<Card.Body>
							<Row>
								<Col md='12' className="mgt-10 align-center"> 
									<label className='form-label'>{clientdata_errormessage}</label>
								</Col>              
							</Row>
						</Card.Body>
					</Card> 
				</div>
            </div>            
        )}

        {locationflag == 0 ? (
            <div>
                <ClientHeader orgObj={orgdata}/>    
				<div className="pd-20">
					<Card>
						<Card.Header>Error</Card.Header>
						<Card.Body>
							<Row>
								<Col md='6' xs="12" className="mgt-10 align-center"> 
									<label className='form-label'>Location access permission is denied.Please enable the location access.</label>
								</Col>              
							</Row>
						</Card.Body>
					</Card> 
				</div>
            </div>            
        ):null}

        { !clientdataflag && !clientdata_errorflag && !makecallflag && ( 
            <div>                            
                <ClientHeader orgObj={orgdata}/>                 
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )}

        {clientdataflag && !makecallflag && locationflag && (                        
            <div>                
            <ClientHeader orgObj={orgdata}/>    
            <Row>
                <Col md="2" xs="12"></Col>
                <Col md="8" xs="12">
                    <div className="pd-10 mgb-50">
                        <Card>
                            <Card.Header>Client Information</Card.Header>
                                <Card.Body>
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>First Name</label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                    :  {firstname}
                                    </Col>                                      
                                </Row>                              
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>Last Name</label>
                                    </Col>              
                                    <Col md='9'  xs="7" className="mgt-10"> 
                                        :  {lastname}
                                    </Col>                                      
                                </Row> 

                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>Email</label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                        : {email}
                                    </Col>                                      
                                </Row> 
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>Mobile</label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                        : {mobile}
                                    </Col>                                      
                                </Row> 
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>PAN Number </label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                        : {pan}
                                    </Col>                                      
                                </Row> 
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>Aadhar Number</label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                        : {aadhar}
                                    </Col>                                      
                                </Row> 
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>Passport</label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                        : {passport}
                                    </Col>                                      
                                </Row> 
                                <Row>
                                    <Col md='3' xs="5" className="mgt-10"> 
                                        <label className='form-label'>Address</label>
                                    </Col>              
                                    <Col md='9' xs="7" className="mgt-10"> 
                                        : {address}
                                    </Col>                                      
                                </Row> 
                            </Card.Body>

                            {checklistflag && (
                                <Card className="width-100">
                                    <Card.Header className="mgt-10">Mandatory Documents</Card.Header>
                                    <Card.Body>
                                        <div>
                                        <form>
                                        {checklist.map((item, i) => (
                                                <Row>
                                                    <Col md='12' xs="12" className="mgt-20"> 
                                                        <label>
                                                        <input type="checkbox"  onChange={e => handleCheckboxChange(e)} className="mgr-5" name={i}/>
                                                            {item.documentname}
                                                        </label>
                                                    </Col>                                                 
                                                </Row>   
                                                ))}
                                            </form>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )}								
                            <Card.Footer>   
                                <Button variant="primary" onClick={initCall}>Join</Button>
                            </Card.Footer>
                        </Card>
                    </div>
                </Col>
            </Row>
          </div>
        )}   		
		<Footer/>
    </div>
  );
}
export default ClientWindow;

