import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./Header.css";
import Logo from "../../assests/orient.png";
import { Container, Row, Col } from 'react-bootstrap';

const Header = () => {
    return (
      <header className="mainheader">
          <Row>
              <Col md="2" className="pdl-10 brandname text-center">DigiKYC</Col>
              <Col md="6" className="pd-10"></Col>
          </Row>
    </header>);
}
export default Header;
