import React, { useState, useEffect, useRef } from "react";
import ClientVideoContext from "./ClientVideoContext";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import $ from "jquery";
import {createWorker} from "tesseract.js";
import * as faceapi from 'face-api.js';
import axios from 'axios';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setMediaBitrate} from '../../mediaBitRate.js';
import {browserName} from 'react-device-detect';

const audio_bandwidth=global.audioBandwidth;
const video_bandwidth=global.videoBandwidth;
const CLIENT_BROWSERS=global.CLIENT_BROWSERS;

let pathname=window.location.pathname;
let path="/client/:id";
const match = matchPath(pathname, {path})
let params=match?.params || {};

let clientreferenceno="";
if(params.id != undefined) {
	var decodeddata = decodeURIComponent(escape(window.atob(params.id)));
	var jsondata=JSON.parse(decodeddata);
	clientreferenceno=jsondata.id;
}
else clientreferenceno=params.id;

let global_otherUser='';
let recorder=null;
let chunks = [];

const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
const FACE_CAPTURE=global.FACE_CAPTURE;

const CLIENT_VIDEO_RESOLUTION_WIDTH=global.CLIENT_VIDEO_RESOLUTION_WIDTH;
const CLIENT_VIDEO_RESOLUTION_HEIGHT=global.CLIENT_VIDEO_RESOLUTION_HEIGHT;
const CLIENT_VIDEO_FRAMERATE=global.CLIENT_VIDEO_FRAMERATE;

var latitude='', longitude='';
var callertype='client';
var devicetype="mobile";
var lengthInMS=10000;
var mediaRecorder=null;
var overlaylayerheight="40";

let global_hrefernceno='';
let global_crefernceno=clientreferenceno;

let clientreconnectInterval='';
let clientreconnectIntervalcount=0;

export const socket=io(SERVER_URL,{
  reconnection: true,
  reconnectionDelay:200,
  reconnectionDelayMax :300,
  reconnectionAttempts:20,
  timeout:600000,
  query:{"type":"client","referenceno":clientreferenceno}
});

var ocr_pan_image='';
var recordedBlob='';
var deferred = new $.Deferred();

const ClientVideoState = ({ children}) => {

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [chat, setChat] = useState([]);
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [userName, setUserName] = useState("");
  const [otherUser, setOtherUser] = useState("");
  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState(true);
  const [msgRcv, setMsgRcv] = useState("");
  const [userPANStatus, setUserPANStatus] = useState(false);
  const [aadharUploadStatus, setaadharUploadStatus] = useState(false);
  const [userPasssportStatus, setUserPassportStatus] = useState(false);
  const [videoabsolutecontainer, setvideoabsolutecontainer] = useState(true);

  const [clientName, setClientName] = useState("");
  const [clientPAN, setClientPAN] = useState("");
  const [clientLatitude, setClientlatitude] = useState("");
  const [clientLongitude, setClientlongitude] = useState("");
  const [client_address,setclient_address] = useState("");
  const [client_referenceno,setclient_referenceno] = useState("");
  const [hostreferenceno,sethostreferenceno]=useState("");
  const [facecapturestatus,setfacecapturestatus] = useState(false);
  const [recordingstatus,setrecordingstatus] = useState(false);
  const [stoprecordingstatus,setstoprecordingstatus] = useState(false);

  const [progressbarPercentage,setProgressprogressbarPercentage] = useState(0);
  const [clientVideoHeight,setclientVideoHeight] = useState("100");
  const [curentcallerType, setcurentcallerType] = useState("");
  const [currentTab,setCurrentTab] = useState(0);
  const [hostcallerType,sethostcallerType]= useState(false);
  const [processpan,setprocesspan]= useState(false);
  const [clientcallerType,setclientcallerType]= useState(false);
  const [overlaypanelstatus,setoverlaypanelstatus]= useState(false);
  const [clientpanocrstatus,setclientpanocrstatus]= useState(false);
  const [userpanImage,setuserpanImage]= useState("");
  const [userpassportImage,setuserpassportImage]= useState("");

  const [userpanImageCSS,setuserpanImageCSS]= useState("hide");
  const [userpassportImageCSS,setuserpassportImageCSS]= useState("hide");  
  const [currentprocesstype,setcurrentprocesstype]= useState("");
  const [clientorgid,setclientorgid]= useState("");
  
  const [overlaywidth,setoverlaywidth]= useState("");
  const [overlayheight,setoverlayheight]= useState("");
  const [otpflag,setotpflag]= useState(false);
  const [overlaytop,setoverlaytop]= useState("");
  const [overlayleft,setoverlayleft]= useState("");
  const [face_Image, setface_Image] = useState("");
  const [face_matching, setface_matching] = useState(false);
  const [initcall, setinitcall] = useState(false);
  const [a2formstatus, seta2formstatus] = useState(false);
  const [capturedImage,setcapturedImage] = useState("");
 
  const [streamflag, setstreamflag] = useState(false);
  const [facingMode,setfacingMode]=useState("user");
  const [endcallMessage,setendcallMessage] = useState(false);
  
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const maxframerate = global.maxframerate;


  useEffect(() => {

      setcurentcallerType("client");
      setclientcallerType(true);
  
      navigator.mediaDevices.getUserMedia({video:{width:{exact:CLIENT_VIDEO_RESOLUTION_WIDTH}, height:{exact:CLIENT_VIDEO_RESOLUTION_HEIGHT}, frameRate:{max:CLIENT_VIDEO_FRAMERATE}},
      //navigator.mediaDevices.getUserMedia({video:{width:{exact:640}, height:{exact:360}, frameRate:10},
      //navigator.mediaDevices.getUserMedia({video:{width:{exact:1280}, height:{exact:720}, frameRate:10},
        audio:{channelCount:2, echoCancellation:true, sampleRate:1000, noiseSuppression:true} })
      .then((currentStream) => {

          setStream(currentStream);   
          myVideo.current.srcObject = currentStream;
      }).catch(function(err) {			
        	
			if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {				
				toast.error("Webcam is not found.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//required track is missing 
			} else if (err.name == "NotReadableError" || err.name == "TrackStartError") {				
				toast.error("webcam or mic are already in use.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//webcam or mic are already in use 
			} else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
				//constraints can not be satisfied by avb. devices 
			} else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {				
				toast.error("permission denied in browser to access webcam or mic.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
				//permission denied in browser 
			} else if (err.name == "TypeError" || err.name == "TypeError") {
				//empty constraints object 
			} else {
				toast.error("Not able to access webcam or mic.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
			}
		});
	  
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`); //Outputs connect_error due to timeout
    });



  socket.on('disconnect',()=> {
    
        console.log("Client side Socket Disconnected");
        console.log("client reference no="+global_crefernceno);    
        console.log("host reference no="+global_hrefernceno);  

        socket.on('connect', () => {

          console.log("after connect="+global_hrefernceno);  
          sethostreferenceno(global_hrefernceno);
          console.log('Client Successfully connected!');
          console.log(socket.id);
          setMe(socket.id);
          setTimeout(() => {

              clientreconnectInterval = setInterval(() => {

              socket.emit("updateclientreconnecttohost",{
                  to:otherUser,
                  hostreferenceno:global_hrefernceno,
                  clientsocketid:socket.id,
                  clientreferenceno:global_crefernceno,
              });

          },200);

        },300);

      });    

    });

    socket.on("updatehostsocketonreconnect", ({hostsocketid}) => { 
        console.log("updatehostsocketonreconnect="+hostsocketid);
        setOtherUser(hostsocketid);	
        global_otherUser=hostsocketid;
    });

    socket.on("hostnotavailableerror", () => { 

        clientreconnectIntervalcount++;
        console.log("hostnotavailableerror");
        if(clientreconnectIntervalcount == 5) {
            clearInterval(clientreconnectInterval);
            alert("Host Not Available");
        }
    });

    socket.on("updateclientsocketonreconnectRcv", ({hostsocketid}) => { 

        clearInterval(clientreconnectInterval);
        console.log("server received the updateclientsocketonreconnect event="+hostsocketid);
        setOtherUser(hostsocketid);	
        global_otherUser=hostsocketid;
    });

	  socket.on("me",(id) => setMe(id)); 		  
	  socket.on("endCall", () => { 
			  //setendcallMessage(true);
			  alert("Host ended the call.You can close the window.");
	  });
	  
	  socket.on("callUser", ({ from, name: callerName, signal }) => { setCall({ isReceivingCall: true, from, name: callerName, signal }); });
	  socket.on("updateProgressbarRcv", ({percentage}) => { setProgressprogressbarPercentage(percentage); });
    socket.on("updateClientDatatoHostRcv", ({name,pan,latitude,longitude,referenceno}) => {
    
    setClientName(name);
    setClientPAN(pan);
    setClientlatitude(latitude);
    setClientlongitude(longitude);
    setclient_referenceno(referenceno);
    global_crefernceno=referenceno;
    
  });

  socket.on("declineCallRcv", () => { 
		alert("Your call is declined");
		window.location.reload();
  });

  socket.on("updateUploadProgressbarRcv", ({percentage}) => { setProgressprogressbarPercentage(percentage); });

  socket.on("aadharuploadcompletedRcv", () => {
      setaadharUploadStatus(true);	  
	    setvideoabsolutecontainer(true);
      setclientVideoHeight("100");
  });

  socket.on("aadharuploaddoneRcv", () => { setaadharUploadStatus(true); });
  socket.on("updatePANStatusRcv", ({status}) => { 
    setfacecapturestatus(true);
    setUserPANStatus(status); 
  });
  socket.on("processPANStepRcv", () => { setoverlaypanelstatus(true);});
  socket.on("updateoverlaystatusRcv", (status) => { setoverlaypanelstatus(false); });

  socket.on("clientsnapshotRcv", ({type}) => { 

    console.log("clientsnapshotRcv");
    console.log(type);

    setcurrentprocesstype(type);
    snapShot(type);
  });
  
  socket.on("updateClientVideoHeightRcv", () => {
    setcurrentprocesstype("face");
    if(CLIENT_BROWSERS.indexOf(browserName)  != -1) snapShot("face");
  });
  
  socket.on("saveocrdoneRcv", () => {	   
		setclientVideoHeight("20");
		setvideoabsolutecontainer(false);
		seta2formstatus(true);
  });
  
  socket.on("msgRcv", ({ name, msg: value, sender }) => {
    setMsgRcv({ value, sender });
    setTimeout(() => { setMsgRcv({});}, 2000);
  });
  
  socket.on("updateUserMedia", ({ type, currentMediaStatus }) => {
    
    if (currentMediaStatus !== null || currentMediaStatus !== []) {
      switch (type) {
        case "video":
          setUserVdoStatus(currentMediaStatus);
          break;
        case "mic":
          setUserMicStatus(currentMediaStatus);
          break;
        default:
          setUserMicStatus(currentMediaStatus[0]);
          setUserVdoStatus(currentMediaStatus[1]);
          break;
      }
    }
  });
  }, []);

useEffect(() => {
  if(recordingstatus) {
      var options = {frameRate: {max:maxframerate}}
      recorder = new MediaRecorder(stream, options);	
      chunks = [];
      recorder.start();
      recorder.ondataavailable = event => chunks.push(event.data);
  }
}, [recordingstatus]);

  socket.on("startclientrecordingRcv", () => {	   
    startRecording();
  });

  const startRecording =() => {
    setrecordingstatus(true);
  }

  const otpsuccess = (flag) => {
    setotpflag(flag);
    socket.emit("otpvalidationdone",{to:otherUser});
  }

  useEffect(() => {

    if(stoprecordingstatus) {
        recorder.stop();
        recorder.onstop = function(e) {
          recordedBlob = new Blob(chunks, { type: "video/mp4" });
          saverecordingdata();
        }
    }

  }, [stoprecordingstatus]);

  socket.on("saveclientrecordingRcv", () => {	   
    setstoprecordingstatus(true);
  });


  function disconnectclientsocket() {

    console.log("inside disconnecthostsocket");
    console.log("hostreferenceno before==="+hostreferenceno);
    console.log("clientreferenceno before=="+clientreferenceno);

    global_hrefernceno=hostreferenceno;
    global_crefernceno=clientreferenceno;
    socket.disconnect();
    socket.connect();

    console.log("afeter connect==="+global_hrefernceno);
    console.log("after connect=="+global_crefernceno);
}


  const saverecordingdata = async () => {		

    const formData=new FormData();								
    formData.append("referenceno",client_referenceno);			
    formData.append("video_blob",recordedBlob);	
    formData.append("latitude",clientLatitude);
    formData.append("longitude",clientLongitude);
    formData.append("hostreferenceno",hostreferenceno);

    try {
      await axios.post(API_URL+'saverecordingdata/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      }).then((response) => {

        var responsedata=response.data;  				
        if(responsedata.status) {			   
          socket.emit("savedrecordingdata",{to:otherUser}); 	   
          toast.success("Data submitted successfully.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
          setclientVideoHeight("20");
          setvideoabsolutecontainer(false);
          seta2formstatus(true);
        }
        else { 
          alert(responsedata.message);
        }		   
      }).catch(err => {			
        console.log("Save Recording Data");
     });  
     
    } catch (err) {
      if (err.response.status === 500) { console.log('There was a problem with the server');} 
      else { console.log(err.response.data.msg);}
    } 

  }
	
  const setCapturedFaceImage =(imagedata) => {	  
	 if(imagedata !="")
		 setface_Image(imagedata);
  }
  
  const setManual_FaceMatching =(flag) => { setface_matching(flag); }
  const aadharuploadcompleted = () => {	  
	  socket.emit("aadharuploadcompleted",{to:otherUser});
	  setaadharUploadStatus(true);	  
	  setvideoabsolutecontainer(true);
      setclientVideoHeight("100");
  }


  function trimCanvas(c) {
    var ctx = c.getContext('2d'),
        copy = document.createElement('canvas').getContext('2d'),
        pixels = ctx.getImageData(0, 0, c.width, c.height),
        l = pixels.data.length,
        i,
        bound = {
            top: null,
            left: null,
            right: null,
            bottom: null
        },
        x, y;
    
    // Iterate over every pixel to find the highest
    // and where it ends on every axis ()
    for (i = 0; i < l; i += 4) {
        if (pixels.data[i + 3] !== 0) {
            x = (i / 4) % c.width;
            y = ~~((i / 4) / c.width);

            if (bound.top === null) {
                bound.top = y;
            }

            if (bound.left === null) {
                bound.left = x;
            } else if (x < bound.left) {
                bound.left = x;
            }

            if (bound.right === null) {
                bound.right = x;
            } else if (bound.right < x) {
                bound.right = x;
            }

            if (bound.bottom === null) {
                bound.bottom = y;
            } else if (bound.bottom < y) {
                bound.bottom = y;
            }
        }
    }
    
    // Calculate the height and width of the content
    var trimHeight = bound.bottom - bound.top,
        trimWidth = bound.right - bound.left,
        trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // Return trimmed canvas
    return copy.canvas;
}
  
  const setClientDetails = (data) => {

    setUserName(data.name);
    setClientName(data.name);
    setName(data.name);
    setClientPAN(data.pan);
    setClientlatitude(data.latitude);
    setClientlongitude(data.longitude);
    setclient_referenceno(data.referenceno);
    global_crefernceno=data.referenceno;
    setclientorgid(data.orgid);
  }

  const snapShot = async(type) => {	

    var videoElement =document.getElementById("client_video");
    var canvaslayer="canvas_layer";
    var canvas=document.querySelector("#"+canvaslayer+" canvas");
    var img=document.querySelector("#"+canvaslayer+" img");
    var ctx = canvas.getContext('2d');
    //var videoWidth=640;
    //var videoHeight=360;
    var videoWidth=$("#client_video").width();
    var videoHeight=$("#client_video").height();
    var imgWidth=videoWidth;
    var imgHeight=videoHeight;
    canvas.width = videoWidth ;
    canvas.height = videoHeight;
    await ctx.drawImage(videoElement,0, 0,videoWidth,videoHeight,10,20,videoWidth,videoHeight);
    setcapturedImage(canvas.toDataURL());
    var trimmedCanvas = trimCanvas(canvas);
    uploadPhoto(type,trimmedCanvas.toDataURL()); 
 }

const uploadPhoto = async(type,imagedata) => {	

  console.log("uploadPhoto=="+type);

  /**********/
  const formData=new FormData();								
  formData.append("referenceno",global_crefernceno);			  
  formData.append("type",type);
  formData.append("imagedata",imagedata);

  try {
      await axios.post(API_URL+'savephoto/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      }).then((response) => {

        console.log("upload response");
        console.log(response);

         var responsedata=response.data;  				
         if(responsedata.status) {		

            if(type == 'face') {  

              console.log("upload host socket id==="+global_otherUser);
              
              setfacecapturestatus(true);
              setclientVideoHeight("40");
              setvideoabsolutecontainer(false);
              socket.emit("photocapturedone",{to:global_otherUser,type:type}); 	  
            }
            else {

              console.log("upload host socket id==="+global_otherUser);

              //toast.success( type.toUpperCase()+" captured successfully.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
              socket.emit("photocapturedone",{to:global_otherUser,type:type}); 	  
            }
         }
         else { 
            alert(responsedata.message);
         }		   
       
      }).catch(err => {			
          console.log(err);
      });  
     
} catch (err) {
  if (err.response.status === 500) { console.log('There was a problem with the server');} 
  else { console.log(err.response.data.msg);}
}

  /*********/
 }

const callUser = async(id) => {
	 
  var callUserInrtervalcount=0;
	var callUserInrterval = setInterval(async() => {

  	if(stream && socket.connected) {
				
        clearInterval(callUserInrterval);
        
        if(streamflag) return;		
        setstreamflag(true);	

        const resp = await axios.get(SERVER_URL+'getsocketid',{params:{id:clientorgid,clientreferenceno:global_crefernceno,clientsocketid:socket.id}});		
        var socketdt=resp.data;
        if(socketdt.status && socketdt.hostorgid == clientorgid) {	
            
            var id=socketdt.hostid;
            var clientsocketid=socket.id;		
            sethostreferenceno(socketdt.hostreferenceno);
            global_hrefernceno=socketdt.hostreferenceno;
            setMe(clientsocketid);	
            setOtherUser(id);	
            global_otherUser=id;
            //const peer = new Peer({ initiator: true, trickle: false, stream });		

            const peer = new Peer({ initiator: true, trickle: false, stream ,
            sdpTransform: (sdp) => {
              //const sdp2 = setMediaBitrate(setMediaBitrate(sdp, 'video',video_bandwidth), 'audio',audio_bandwidth);
              const sdp2 =  setMediaBitrate(sdp, 'video',video_bandwidth);
              return sdp2;
              }
            });	
              
            peer.on('error', (err) => {				
              alert("Connection failed.Please try to connect again.");
              window.location.reload();
              return false;
            });	

          peer.on("signal", (data) => {
            socket.emit("callUser", {userToCall:id, signalData: data, from: clientsocketid, name,});
          });

          peer.on("stream", (currentStream) => {	
            userVideo.current.srcObject = currentStream;
          });

          socket.on("callAccepted", ({ signal, userName }) => {
            setCallAccepted(true);
            setUserName(userName);
            peer.signal(signal);
            socket.emit("updateMyMedia", { type: "pan", currentMediaStatus: [myMicStatus, myVdoStatus], });    
            var client_obj={};
            client_obj['referenceno']=client_referenceno;
            client_obj['browsername']=browserName.toString();
            socket.emit("updateClientDatatoHost", { to: id, name:clientName, pan:clientPAN,latitude:clientLatitude, longitude: clientLongitude, referenceno:client_obj});
          });    
          connectionRef.current = peer;
        }
        else {			
          alert("Host not Available");			
          window.location.reload();
        }
  	}
    else {
			
			callUserInrtervalcount++;
			if(callUserInrtervalcount == 5) {        
				clearInterval(callUserInrterval);
				toast.error("Unable to connect.Please try again.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
			}

		}

  },500);
}

  const updateVideo = () => {
    setMyVdoStatus((currentStatus) => {
      socket.emit("updateMyMedia", { type: "video", currentMediaStatus: !currentStatus, });
      stream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const updateMic = () => {
    setMyMicStatus((currentStatus) => {
      socket.emit("updateMyMedia", { type: "mic", currentMediaStatus: !currentStatus, });
      stream.getAudioTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const leaveCall = () => {
    setCallEnded(true);
    connectionRef.current.destroy();
    socket.emit("endCall", { id: otherUser,host_referenceno:hostreferenceno});	
    stream.getAudioTracks()[0].stop();
    stream.getVideoTracks()[0].stop();
  };

  const leaveCall1 = () => { socket.emit("endCall", { id:otherUser,host_referenceno:hostreferenceno}); };

  const sendMsg = (value) => {
    
    socket.emit("msgUser", { name, to: otherUser, msg: value, sender: name });
    let msg = {};
    msg.msg = value;
    msg.type = "sent";
    msg.timestamp = Date.now();
    msg.sender = name;
    setChat([...chat, msg]);
  };

  useEffect(() => {	  
	if(stream) {
		setinitcall(true);
		callUser('');		
    }		
  },[stream]);
  
  return (
    <ClientVideoContext.Provider
      value={{ call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser, leaveCall, setClientDetails,
        sendMsg, msgRcv, chat, setChat, setMsgRcv, setOtherUser, leaveCall1, userName, myVdoStatus,
        setMyVdoStatus, userVdoStatus, setUserVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic,
        clientName, clientPAN, clientLatitude, clientLongitude, client_address,userPANStatus,progressbarPercentage,
        clientVideoHeight,currentTab,otherUser,aadharUploadStatus,curentcallerType,hostcallerType,clientcallerType,
        processpan,overlaypanelstatus,userpanImage,clientpanocrstatus,userpassportImage,userPasssportStatus,
        videoabsolutecontainer,aadharuploadcompleted,overlaywidth,overlayheight,overlaytop,overlayleft,client_referenceno,
        setCapturedFaceImage,setManual_FaceMatching,userpanImageCSS,userpassportImageCSS,initcall,a2formstatus,capturedImage,facecapturestatus,endcallMessage,otpsuccess,otpflag,disconnectclientsocket
      }}
    >
      {children}
    </ClientVideoContext.Provider>
  );
};
export default ClientVideoState;
