import React, {useState,useEffect} from 'react';
import '../../Config.js';
import axios from 'axios';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var latitude='',longitude;
const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
const Logger = ({loggerObj}) => {

    console.log("====loggerObj====");
    console.log(loggerObj);
}
export default Logger;

