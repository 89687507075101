import React, { useContext,Fragment,useEffect,useState , useRef} from 'react';
import Message from './Message';
import Progress from './Progress';
import ClientVideoContext from "./ClientVideoState/ClientVideoContext";
import { socket } from "./ClientVideoState/ClientVideoState";
import { Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import "./FileUpload.css";
import '../Config.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL=global.CONFIG_API_URL;
const AadharOTPVerification = () => {

  const { call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser,
    leaveCall, answerCall, sendMsg: sendMsgFunc, msgRcv, chat, setChat, userName, myVdoStatus,
    userVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic, clientName, clientPAN,
    clientLatitude, clientLongitude, client_address,userPANStatus,progressbarPercentage,clientVideoHeight,currentTab,
	otherUser,aadharuploadcompleted,client_referenceno
  } = useContext(ClientVideoContext);

  const [aadharnumber, setAadharnumber] = useState('');
  const [otp, setotp] = useState('');
  const [message, setMessage] = useState('');
  const [sendotpstatus, setsendotpstatus] = useState(false);
  const {register, handleSubmit, formState: { errors } } = useForm();
  const [aadharrequestid,setaadharrequestid] = useState('');
  let sendotpbtnRef = useRef();
  let verifyotpbtnRef= useRef();


  const validateAadharNumber =(e)=> {
    setAadharnumber(e.target.value);
  };

  const validateOTP =(e)=>{
      setotp(e.target.value);
  };

  const onSubmit  = async(data)=> {	
	
		var aadharsendInrtervalcount=0;
		var aadharsendInrterval = setInterval(async() => {		
			
			if(socket.connected) {

				clearInterval(aadharsendInrterval);
				if(sendotpbtnRef.current){
					sendotpbtnRef.current.setAttribute("disabled", "disabled");
				}

				const formData=new FormData();		        						
				formData.append("data",JSON.stringify(data));
				formData.append("referenceno",client_referenceno);	
				const response = await axios.post(API_URL+'sendaadharotp/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}); 
				var responsedata=response.data;          
				if(responsedata.status) {
					sendotpbtnRef.current.removeAttribute("disabled");
					setsendotpstatus(true);
					setaadharrequestid(responsedata.requestid);
					toast.success("OTP has been sent to your mobile no.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});									  
				}
				else { 
					sendotpbtnRef.current.removeAttribute("disabled");
					toast.error(responsedata.message,{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}
			else {
				
				aadharsendInrtervalcount++;
				if(aadharsendInrtervalcount == 5) {
					clearInterval(aadharsendInrterval);
					toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				}
			}

		},1000);
}

const handleOTPSubmit = async(data)=> {	
  
	var aadharotpInrtervalcount=0;
	var aadharotpInrterval = setInterval(async() => {

		if(socket.connected) {

			clearInterval(aadharotpInrterval);
			if(verifyotpbtnRef.current) {				
				verifyotpbtnRef.current.setAttribute("disabled", "disabled");
			}
			
			const formData=new FormData();		        						
			formData.append("data",JSON.stringify(data));
			formData.append("requestid",aadharrequestid);
			formData.append("referenceno",client_referenceno);	
			const response = await axios.post(API_URL+'verifyaadharotp/', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}); 

			var responsedata=response.data;   
			if(responsedata.status) {
				verifyotpbtnRef.current.removeAttribute("disabled");
				socket.emit("aadharuploaddone", {to: otherUser});
				aadharuploadcompleted();			  
				toast.success("Aadhar Data Fetched Successfully.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
			}
			else { 
				verifyotpbtnRef.current.removeAttribute("disabled");
				toast.error("OTP is not Matching.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
			}
		}
		else {
			
			aadharotpInrtervalcount++;
			if(aadharotpInrtervalcount == 5) {
				clearInterval(aadharotpInrterval);
				toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
			}
		}

	},1000);
}

const resendOTP =async() => {
  
	const formData=new FormData();		        						
	formData.append("referenceno",client_referenceno);	
	const response = await axios.post(API_URL+'resendotp/', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}); 
	
	var responsedata=response.data;          
	if(responsedata.status) {
		toast.info("OTP has been sent succcessfully.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});
	}
	else { 			
		toast.error(responsedata.message,{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});
	}
}

  return (
    <div className="fileuplaodlayer">
		<div className="aadhar_upload_header">OTP Based Aadhar Validation</div>
		<Fragment>      
		  {message ? <Message msg={message} /> : null}

		  {!sendotpstatus && (

			<form onSubmit={handleSubmit(onSubmit)}>
				<Row className="pd-10">  	
					<Col md='12' className="mgt-10"> 
						<div>
							<label className="form-label" htmlFor="aadharno">Enter Aadhar No</label>
						</div>											 
						<div>
							<input type="text" id="aadharno" 
								className="form-control" 
									aria-invalid={errors.aadharno ? "true" : "false"} 
									{...register('aadharno', { 
										required: true
									})} 
						  	/>
						</div>						  
						<div>
							{errors.aadharno && (
								<span role="alert" className="errormessage">Please enter correct value</span> 
							)}
						</div>
					</Col> 	
					<Col md='12' className="mgt-10 align-center"> 
						<input ref={sendotpbtnRef} className="btn btn-primary" type="submit" value="Send OTP"/>																			
					</Col>							
				</Row>      
			</form>
		)}

		{sendotpstatus && (

			<form onSubmit={handleSubmit(handleOTPSubmit)}>		
				<Row className="pd-10">							
					<Col md='12' className="mgt-15"> 
						<div><label className="form-label" htmlFor="otp">Please Enter the OTP</label></div>											 
						<div><input type="text"  id="otp" className="form-control" aria-invalid={errors.otpno ? "true" : "false"} {...register('otp', { required: true })} /></div>												 
						<div>
							{errors.otp && (
								<span role="alert" className="errormessage">Please enter valid OTP</span> 
							)}
						</div>
					</Col>  			
					<Col md='12' className="mgt-10 align-center"> 
						<input ref={verifyotpbtnRef} className="btn btn-primary"  type="submit" />																			
					</Col> 							
				</Row>
			</form>
		  )}

		</Fragment>
    </div>
  );
};
export default AadharOTPVerification;
