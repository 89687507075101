import React, { useContext,Fragment,useEffect,useState } from 'react';
import Message from './Message';
import Progress from './Progress';
import ClientVideoContext from "./ClientVideoState/ClientVideoContext";
import { socket } from "./ClientVideoState/ClientVideoState";
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import "./FileUpload.css";
import '../Config.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL=global.CONFIG_API_URL;

const FileUpload = () => {

  const { call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser,
    leaveCall, answerCall, sendMsg: sendMsgFunc, msgRcv, chat, setChat, userName, myVdoStatus,
    userVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic, clientName, clientPAN,
    clientLatitude, clientLongitude, client_address,userPANStatus,progressbarPercentage,clientVideoHeight,currentTab,
	otherUser,aadharuploadcompleted,client_referenceno
  } = useContext(ClientVideoContext);


  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [passcode, setPasscode] = useState('');
  const [fileuploadstatus, setFileUploadStatus] = useState(false);

  const onChange =(e)=> {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onPasscodeChange =(e)=>{
      setPasscode(e.target.value);
  };

  const onSubmit = async(e) => {

    e.preventDefault();    
    const formData = new FormData();
    formData.append('file',file);
    formData.append('passcode',passcode);
	formData.append('referenceno',client_referenceno);
	
    try {

		const response = await axios.post(API_URL+'aadharupload/', formData, {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		});
	 
		 var  responsedata=response.data;      
		 if(responsedata.status) {			
			  setTimeout(() => setUploadPercentage(0), 10000);	  
			  socket.emit("aadharuploaddone", {to: otherUser});
			  setFileUploadStatus(true);
			  setMessage("File is Uploaded Successfully.");
			  aadharuploadcompleted();			  
			  toast.success("File Uploaded Successfully.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
		  }
		  else {
			var errormsg=responsedata.message;
			setMessage(errormsg);
		  }
	  } catch (err) {  
		toast.error("There was a problem with the server.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								   
		setUploadPercentage(0)
      }
  };

  return (
    <div className="fileuplaodlayer">
		<div className="aadhar_upload_header">Uplaod Offline Aadhar ZIP File</div>
		<Fragment>      
		  {message ? <Message msg={message} /> : null}
		  <form onSubmit={onSubmit}>
			<Row className="pd-10">            
				<Col md='12'>
					<label className="form-label">Choose ZIP File</label>
				</Col>
				<Col md='12'>                  
				  <input type='file' className='form-control' name="aadharfile" id='customFile' onChange={onChange} />              
				</Col>
				
				<Col md='12' className="mgt-10">
					<label className="form-label">Please Enter Your Passcode</label>
				</Col>
				<Col md='12'>
					<input type='text' className='form-control' id='passcode' onChange={onPasscodeChange} />
				</Col>
				
				<Col md='12' className="mgt-10">
				  <Progress percentage={uploadPercentage} />
				</Col>
				<Col md='12' className="align-center">
				  <input
					type='submit'
					value='Upload'
					className='btn btn-danger btn-block mt-4'
				  />
				</Col>
			</Row>      
		  </form>
		</Fragment>
    </div>
  );
};
export default FileUpload;
