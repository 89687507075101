import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./HostHeader.css";
import Logo from "../../assests/orient.png";
import HostVideoContext from "../HostVideoState/HostVideoContext";
import {FullScreen,useFullScreenHandle} from "react-full-screen";
import HostOptions from "../HostOptions/HostOptions";
import HostVideo from "../HostVideo/HostVideo";
import fullscreen from "../../fullscreen.png";
import loginuser from "../../loginuser.png";
import {Dropdown} from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { useStopwatch } from 'react-timer-hook';
import LoadingOverlay from 'react-loading-overlay-ts';

const HostHeader = ({hostObj}) => {

  const { callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, callUser,
    leaveCall, answerCall, msgRcv, chat, setChat, userName, myVdoStatus,
    userVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic, clientName, clientPAN,
    clientLatitude, clientLongitude, client_address,userPANStatus,progressbarPercentage,clientVideoHeight,currentTab,aadharUploadStatus,curentcallerType,setHostData,hostlogout,loaderoverlaystatus,loaderoverlaytext
  } = useContext(HostVideoContext);

  const handle=useFullScreenHandle();
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const [sessionvalidation,setsessionvalidation] = useState(false);
  const [hostname,sethostname] = useState(false);
  const [org_logo,setorg_logo] = useState("");
  const [org_name,setorg_name] = useState("");
  const [org_logo_height,setorg_logo_height] = useState("");
  const [isOverlayActive,setOverlayActive] = useState(false);

  	useEffect(async() => {
		setOverlayActive(loaderoverlaystatus);
	},[loaderoverlaystatus]);

	useEffect(async() => {	  
	  if(hostObj) {
		  setHostData(hostObj);
		  setsessionvalidation(true);
		  sethostname(hostObj.firstname+" "+hostObj.lastname);
		  setorg_logo(hostObj.logo);
		  setorg_name(hostObj.orgname);
		  setorg_logo_height(hostObj.logoheight);
	  }
	},[hostObj]);
	
	useEffect(async() => {	  
		if(callAccepted) {
			start();
		}
	  },[callAccepted]);
	
	  
	const logout=()=> {		
		sessionStorage.setItem("hostdata","");
		hostlogout();
	};
	

    return (	
		  <div>
			<LoadingOverlay active={isOverlayActive} spinner text={loaderoverlaytext}>
			  <FullScreen handle={handle}>
				<div className="mainheader">
				  <Row>
					  <Col md="2" className="pd-10 brandname">DigiKYC</Col>
					  <Col md="2" className="pdl-10 orgname"><img src={org_logo} height={org_logo_height}/></Col>
					  <Col md="4" className="pd-10">
						{curentcallerType == "host" && callAccepted && (
						  <div className='clientaddress'> 
							  <div className='clientaddress'> {clientName} - {clientPAN} </div>
							  <div className='clientaddress'>{client_address} </div>
						  </div>
						)}
					  </Col>
					  <Col md="2" className="pd-5 align-center">						
					  	<div style={{color:'#FFF',display:'inline-block'}}>
							<div className="timertext"><span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span></div>							
						</div>
						<div style={{display:'inline-block',float:'right'}}><img src={fullscreen} style={{marginTop:"15px"}} width="35" height="35" onClick={handle.enter} /></div>
					  </Col>					  					  
					  <Col md="2" className="pd-10">
						  {sessionvalidation && (
								<Dropdown>
								  <Dropdown.Toggle variant="default" className="login_user">
									<img src={loginuser} width="32" height="32"/> {hostname}
								  </Dropdown.Toggle>
								  <Dropdown.Menu>
									<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>							
								  </Dropdown.Menu>
								</Dropdown>
							)}
						</Col>				
				  </Row>	
				</div>
			 <HostVideo/>
			 <HostOptions/>
			</FullScreen>
			</LoadingOverlay>			
		</div>
	);
}
export default HostHeader;
