import React, { useState, useEffect, useRef } from "react";
import HostVideoContext from "./HostVideoContext";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import $ from "jquery";
import {createWorker} from "tesseract.js";
import * as faceapi from 'face-api.js';
import axios from 'axios';
import '../../Config.js';
import {setMediaBitrate} from '../../mediaBitRate.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
const PAN_CAPTURE=global.PAN_CAPTURE;
const AADHAR_CAPTURE=global.AADHAR_CAPTURE;
const PASSPORT1_CAPTURE=global.PASSPORT1_CAPTURE;
const PASSPORT2_CAPTURE=global.PASSPORT2_CAPTURE;
const SIGNATURE_CAPTURE=global.SIGNATURE_CAPTURE;
const VIDEO_RECORDING=global.VIDEO_RECORDING;
const CLIENT_BROWSERS=global.CLIENT_BROWSERS;

const HOST_VIDEO_RESOLUTION_WIDTH=global.HOST_VIDEO_RESOLUTION_WIDTH;
const HOST_VIDEO_RESOLUTION_HEIGHT=global.HOST_VIDEO_RESOLUTION_HEIGHT;
const HOST_VIDEO_FRAMERATE=global.HOST_VIDEO_FRAMERATE;
const HOST_AUDIO_SAMPLERATE=global.HOST_AUDIO_SAMPLERATE;
const HOST_AUDIO_SAMPLESIZE=global.HOST_AUDIO_SAMPLESIZE;
const GOOGLEMAP_APIKEY=global.GOOGLEMAP_APIKEY;

const audio_bandwidth=global.audioBandwidth;
const video_bandwidth=global.videoBandwidth;
const maxframerate = global.maxframerate;

let global_hrefernceno='';
let global_crefernceno='';
let global_orgid='';
let global_hostsocketid='';
let global_clientsocketid='';

var callertype='host';
var recorder=null;
let chunks = [];
var photocaptureinterval='';
var recordedBlob='';

export const socket=io(SERVER_URL, { 
    reconnection: true,
    reconnectionDelay:200,
    reconnectionDelayMax:300,
    reconnectionAttempts:10,
    timeout:600000,
    query:{ "type":"host"}
});

const HostVideoState = ({ children}) => {

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [chat, setChat] = useState([]);
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [userName, setUserName] = useState("");
  const [otherUser, setOtherUser] = useState("");
  
  let [hostreferenceno,sethostreferenceno] = useState("");  
  let [client_referenceno,setclient_referenceno] = useState("");
  let [clientreferenceno,setclientreferenceno] = useState("");
  const [recordingstatus,setrecordingstatus] = useState(false);

  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState(true);
  const [msgRcv, setMsgRcv] = useState("");
  const [userPANStatus, setUserPANStatus] = useState(false);
  const [aadharUploadStatus, setaadharUploadStatus] = useState(false);
  const [userPasssportStatus, setUserPassportStatus] = useState(false);
  const [userPasssport2Status, setUserPassport2Status] = useState(false);
  const [videoabsolutecontainer, setvideoabsolutecontainer] = useState(true);  

  const [ocrsubmitcounter,setocrsubmitcounter] = useState(0); 
  const [ocrsubmitattempts,setocrsubmitattempts] = useState(2); 
  
  const [clientName, setClientName] = useState("");
  const [clientPAN, setClientPAN] = useState("");
  const [clientLatitude, setClientlatitude] = useState("");
  const [clientLongitude, setClientlongitude] = useState("");
  const [client_address,setclient_address] = useState("");
  const [clientBrowserName, setclientBrowserName] = useState("");
 
  const [progressbarPercentage,setProgressprogressbarPercentage] = useState(0);
  const [clientVideoHeight,setclientVideoHeight] = useState("100");
  const [curentcallerType, setcurentcallerType] = useState("");
  const [currentTab,setCurrentTab] = useState(0);
  const [hostcallerType,sethostcallerType]= useState(false);
  const [processpan,setprocesspan]= useState(false);
  const [clientcallerType,setclientcallerType]= useState(false);
  const [overlaypanelstatus,setoverlaypanelstatus]= useState(false);
  const [clientpanocrstatus,setclientpanocrstatus]= useState(false);
  const [userpanImage,setuserpanImage]= useState("");
  
  const [capturepan,setcapturepan]= useState(false);
  const [captureaadhar,setcaptureaadhar]= useState(false);
  const [capturepassport1,setcapturepassport1]= useState(false);
  const [capturepassport2,setcapturepassport2]= useState(false);
  const [capturesignature,setcapturesignature]= useState(false);
  
  const [videorecordingcompleted,setvideorecordingcompleted] = useState(false);
  const [userpassportImage,setuserpassportImage]= useState("");
  const [userpassport2Image,setuserpassport2Image]= useState("");
  const [signatureImage,setsignatureImage]= useState("");
  
  const [userpanImageCSS,setuserpanImageCSS]= useState("hide");
  const [userpassportImageCSS,setuserpassportImageCSS]= useState("hide");  
  const [userpassport2ImageCSS,setuserpassport2ImageCSS]= useState("hide");
  const [signatureImageCSS,setsignatureImageCSS]= useState("hide");
  
  const [currentprocesstype,setcurrentprocesstype]= useState("");  
  const [overlaywidth,setoverlaywidth]= useState("");
  const [overlayheight,setoverlayheight]= useState("");

  const [photocapturedonestatus,setphotocapturedonestatus]= useState(false);
  const [photocapturedonetype,setphotocapturedonetype]= useState("");

  const [overlaytop,setoverlaytop]= useState("");
  const [overlayleft,setoverlayleft]= useState("");
  const [face_Image, setface_Image] = useState("");
  const [face_matching, setface_matching] = useState(false);
  const [hostorgid, sethostorgid] = useState("");
  const [loaderoverlaytext,setloaderoverlaytext] = useState("Connecting...");
  const [loaderoverlaystatus, setloaderoverlaystatus] = useState(false);
  const [stopvideorecordingstatus, setstopvideorecording] = useState(false);
  const [videorecordingside, setvideorecordingside] = useState("");
  const [videouploadcompletestatus,setvideouploadcompletestatus] = useState(false);
  const [clientinternetspeed,setclientinternetspeed] = useState('');
  const [clientinternetspeedlastupdated,setclientinternetspeedlastupdated] = useState('');

  let [recordScreen, setRecordScreen] = React.useState(true);
  let [audio, setAudio] = React.useState(true);
  
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

/****************Media Recorder Start*****************/
  /**
 * Checks whether the argument is an object
 * @param {any} o
 */
   function isObject(o) {
    return o && !Array.isArray(o) && Object(o) === o;
  }
  
  /**
   * Checks whether constraints are valid
   * @param {MediaStreamConstraints} mediaType
   */
  function validateMediaTrackConstraints(mediaType) {
    let supportedMediaConstraints = null;
  
    if (navigator.mediaDevices) {
      supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
    }
  
    if (supportedMediaConstraints === null) {
      return;
    }
  
    let unSupportedMediaConstraints = Object.keys(mediaType).filter(
      (constraint) => !supportedMediaConstraints[constraint]
    );
  
    if (unSupportedMediaConstraints.length !== 0) {
      let toText = unSupportedMediaConstraints.join(',');
      console.error(
        `The following constraints ${toText} are not supported on this browser.`
      );
    }
  }
  
  const noop = () => {};
  
  /**
   *
   * @callback Callback
   * @param {Blob} blob
   *
   * @callback ErrorCallback
   * @param {Error} error
   *
   * @typedef MediaRecorderProps
   * @type {object}
   * @property {BlobPropertyBag} blobOptions
   * @property {boolean} recordScreen
   * @property {function} onStart
   * @property {Callback} onStop
   * @property {Callback} onDataAvailable
   * @property {ErrorCallback} onError
   * @property {object} mediaRecorderOptions
   * @property {MediaStreamConstraints} mediaStreamConstraints
   *
   * @typedef MediaRecorderHookOptions
   * @type {object}
   * @property {Error} error
   * @property {string} status
   * @property {Blob} mediaBlob
   * @property {boolean} isAudioMuted
   * @property {function} stopRecording,
   * @property {function} getMediaStream,
   * @property {function} clearMediaStream,
   * @property {function} startRecording,
   * @property {function} pauseRecording,
   * @property {function} resumeRecording,
   * @property {function} muteAudio
   * @property {function} unMuteAudio
   * @property {MediaStream} liveStream
   *
   * @param {MediaRecorderProps}
   * @returns {MediaRecorderHookOptions}
   */
  function useMediaRecorder({
    blobOptions,
    recordScreen,
    onStop = noop,
    onStart = noop,
    onError = noop,
    onDataAvailable = noop,
    mediaRecorderOptions,
    mediaStreamConstraints = {}
  }) {
    let mediaChunks = React.useRef([]);
    let mediaStream = React.useRef(null);
    let mediaRecorder = React.useRef(null);
    let [error, setError] = React.useState(null);
    let [status, setStatus] = React.useState('idle');
    let [mediaBlob, setMediaBlob] = React.useState(null);
    let [isAudioMuted, setIsAudioMuted] = React.useState(false);
  
    async function getMediaStream() {
      if (error) {
        setError(null);
      }
  
      setStatus('acquiring_media');
  
      try {
        let stream;

        let dest;
        let OutgoingAudioMediaStream;
        let IncomingAudioMediaStream;
        let audioContext;
        let audioIn_01;
        let audioIn_02;
  
        if (recordScreen) {
          stream = await window.navigator.mediaDevices.getDisplayMedia(
            mediaStreamConstraints
          );
        } else {
          stream = await window.navigator.mediaDevices.getUserMedia(
            mediaStreamConstraints
          );
        }
  
        if (recordScreen && mediaStreamConstraints.audio) {
          
         /* let audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio: {
              channelCount: 2,
              sampleRate: HOST_AUDIO_SAMPLERATE,
              sampleSize:HOST_AUDIO_SAMPLESIZE,
              volume: 1
            }    
            
          });
          
          audioStream.getAudioTracks()
            .forEach((audioTrack) => stream.addTrack(audioTrack));
        
          let uservideostream=userVideo.current.srcObject;
          uservideostream
            .getAudioTracks()
            .forEach((audioTrack1) => stream.addTrack(audioTrack1)); */

            OutgoingAudioMediaStream = new MediaStream();
            OutgoingAudioMediaStream.addTrack(myVideo.current.srcObject.getAudioTracks()[0]);

            IncomingAudioMediaStream = new MediaStream();
            IncomingAudioMediaStream.addTrack(userVideo.current.srcObject.getAudioTracks()[0]);


            audioContext = new AudioContext();

            audioIn_01 = audioContext.createMediaStreamSource(OutgoingAudioMediaStream);
            audioIn_02 = audioContext.createMediaStreamSource(IncomingAudioMediaStream);
            
            dest = audioContext.createMediaStreamDestination();

            audioIn_01.connect(dest);
            audioIn_02.connect(dest);
            
            dest.stream.addTrack(stream.getVideoTracks()[0]);
        }
  
        mediaStream.current = dest.stream;
        setStatus('ready');

      } catch (err) {
        setError(err);
        setStatus('failed');
      }
    }
  
    function clearMediaStream() {

      if (mediaRecorder.current) {
        mediaRecorder.current.removeEventListener(
          'dataavailable',
          handleDataAvailable
        );
        mediaRecorder.current.removeEventListener('stop', handleStop);
        mediaRecorder.current.removeEventListener('error', handleError);
        mediaRecorder.current = null;
      }
  
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => track.stop());
        mediaStream.current = null;
        mediaChunks.current = [];
      }
    }
  
    async function startRecording() {
      if (error) {
        setError(null);
      }
  
      if (!mediaStream.current) {
        await getMediaStream();
      }
  
      mediaChunks.current = [];
  
      if (mediaStream.current) {
        mediaRecorder.current = new MediaRecorder(
          mediaStream.current,
          mediaRecorderOptions
        );
        mediaRecorder.current.addEventListener(
          'dataavailable',
          handleDataAvailable
        );
        mediaRecorder.current.addEventListener('stop', handleStop);
        mediaRecorder.current.addEventListener('error', handleError);
        mediaRecorder.current.start();
        setStatus('recording');
        onStart();
      }
    }
  
    function handleDataAvailable(e) {
      if (e.data.size) {
        mediaChunks.current.push(e.data);
      }
      onDataAvailable(e.data);
    }
  
    function handleStop() {
    
      let [sampleChunk] = mediaChunks.current;
      let blobPropertyBag = Object.assign(
        { type: sampleChunk.type },
        blobOptions
      );
      let blob = new Blob(mediaChunks.current, blobPropertyBag);
      setStatus('stopped');
      setMediaBlob(blob);
      onStop(blob);
    }
  
    function handleError(e) {
      setError(e.error);
      setStatus('idle');
      onError(e.error);
    }
  
    function muteAudio(mute) {
      setIsAudioMuted(mute);
  
      if (mediaStream.current) {
        mediaStream.current.getAudioTracks().forEach((audioTrack) => {
          audioTrack.enabled = !mute;
        });
      }
    }
  
    function pauseRecording() {
      if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
        setStatus('paused');
        mediaRecorder.current.pause();
      }
    }
  
    function resumeRecording() {
      if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
        mediaRecorder.current.resume();
        setStatus('recording');
      }
    }
  
    function stopRecording() {
    
      if (mediaRecorder.current) {
        setStatus('stopping');
        mediaRecorder.current.stop();
        // not sure whether to place clean up in useEffect?
        // If placed in useEffect the handler functions become dependencies of useEffect
        mediaRecorder.current.removeEventListener(
          'dataavailable',
          handleDataAvailable
        );
        mediaRecorder.current.removeEventListener('stop', handleStop);
        mediaRecorder.current.removeEventListener('error', handleError);
        mediaRecorder.current = null;
        clearMediaStream();
      }
    }
  
    React.useEffect(() => {
      if (!window.MediaRecorder) {
        throw new ReferenceError(
          'MediaRecorder is not supported in this browser. Please ensure that you are running the latest version of chrome/firefox/edge.'
        );
      }
  
      if (recordScreen && !window.navigator.mediaDevices.getDisplayMedia) {
        throw new ReferenceError(
          'This browser does not support screen capturing'
        );
      }
  
      if (isObject(mediaStreamConstraints.video)) {
        validateMediaTrackConstraints(mediaStreamConstraints.video);
      }
  
      if (isObject(mediaStreamConstraints.audio)) {
        validateMediaTrackConstraints(mediaStreamConstraints.audio);
      }
  
      if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
        if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
          console.error(
            `The specified MIME type supplied to MediaRecorder is not supported by this browser.`
          );
        }
      }
    }, [mediaStreamConstraints, mediaRecorderOptions, recordScreen]);
  
    return {
      error,
      status,
      mediaBlob,
      isAudioMuted,
      stopRecording,
      getMediaStream,
      startRecording,
      pauseRecording,
      resumeRecording,
      clearMediaStream,
      muteAudio: () => muteAudio(true),
      unMuteAudio: () => muteAudio(false),
      get liveStream() {
        if (mediaStream.current) {
          return new MediaStream(mediaStream.current.getVideoTracks());
        }
        return null;
      }
    };
  }

  /*****************Media Recorder End***************/

  let {
    status,
    liveStream,
    mediaBlob,
    pauseRecording,
    resumeRecording,
    stopRecording,
    getMediaStream,
    startRecording,
    clearMediaStream
  } = useMediaRecorder({
    recordScreen,
    mediaStreamConstraints: {
      video:{width:HOST_VIDEO_RESOLUTION_WIDTH,frameRate:HOST_VIDEO_FRAMERATE},
      audio:{
        channelCount:2, 
        echoCancellation:true, 
        sampleRate:HOST_AUDIO_SAMPLERATE, 
        sampleSize:HOST_AUDIO_SAMPLESIZE,
        noiseSuppression:true
      }
    }
  });


  useEffect(() => clearMediaStream, []);

   useEffect( async() => {

    if(clientLatitude !="" && clientLatitude !="undefined" && clientLongitude !="" && clientLongitude !="undefined") {

      try {           

        var addressurl="https://maps.googleapis.com/maps/api/geocode/json?latlng="+clientLatitude+","+clientLongitude+"&key="+GOOGLEMAP_APIKEY;
        var addressresp = await axios.get(addressurl);   
        var googledata=addressresp.data;
        var google_results=googledata.results;
        var google_formattedaddrs=google_results[0].formatted_address;
        setclient_address(google_formattedaddrs);

      } catch(error) {

        let errorobj={};
        errorobj.module="VKYC-HOST";
        errorobj.message="Getting Geo-Location API Call Error";
        errorobj.description=error;			
        console.log(errorobj);
      }
    }
  },[clientLatitude,clientLongitude]);
  
  useEffect(() => {
	  
      setcurentcallerType(callertype);
      if(callertype=="host")
        sethostcallerType(true);  	
		navigator.mediaDevices
      .getUserMedia({video:{width: {exact:HOST_VIDEO_RESOLUTION_WIDTH},height:{exact:HOST_VIDEO_RESOLUTION_HEIGHT}, frameRate:{max:HOST_VIDEO_FRAMERATE}},
        audio:{channelCount:2, echoCancellation:true, sampleRate:1000, noiseSuppression:true} })
      .then((currentStream) => {
        setStream(currentStream);   
        myVideo.current.srcObject = currentStream;
		  }).catch(function(err) {		

			if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {				
				toast.error("Webcam is not found.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//required track is missing 
			} else if (err.name == "NotReadableError" || err.name == "TrackStartError") {				
				toast.error("webcam or mic are already in use.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
				//webcam or mic are already in use 
			} else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
				//constraints can not be satisfied by avb. devices 
			} else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {				
				toast.error("permission denied in browser to access webcam or mic.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
				//permission denied in browser 
			} else if (err.name == "TypeError" || err.name == "TypeError") {
				//empty constraints object 
			} else {
				toast.error("Not able to access webcam or mic.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});								
			}
		});
	
	socket.on("me", (id) => { 
    setMe(id);
    global_hostsocketid=id;
  }); 

  socket.on("endCall", () => { 
    alert("Client ended the call.You can close the window.");
    window.location.reload();
  });

  socket.on("updatehostsocketonreconnectRcv", ({clientsocketid}) => { 
      console.log("server received the updatehostreconnecttoclient event");
      global_clientsocketid=clientsocketid;
      setOtherUser(clientsocketid);
  });

  socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`); //Outputs connect_error due to timeout
  });
  
  socket.on('disconnect',()=> {

      console.log("Host side Socket Disconnected");
      socket.on('connect', () => {

        console.log("Connection Established");
        console.log("clientreferenceno after=="+global_hrefernceno);
        console.log("hostreferenceno after=="+global_crefernceno);
    
        sethostreferenceno(global_hrefernceno);
        setclientreferenceno(global_crefernceno);
        setclient_referenceno(global_crefernceno);
    
        setMe(socket.id);

        console.log("host New Socket Id=="+socket.id);

        global_hostsocketid=socket.id;
    
          socket.emit("updatehostreconnecttoclient",{
            clientreferenceno:global_crefernceno,
            hostreferenceno:global_hrefernceno,
            hostsocketid:socket.id,
            clientsocketid:global_clientsocketid
          });
          
      });

  });

  

  socket.on("updateclientsocketonreconnect", ({clientsocketid}) => { 

      console.log("updateclientsocketonreconnect"+clientsocketid);
      global_clientsocketid=clientsocketid;
      setOtherUser(clientsocketid);	
  });

	socket.on("callUser", ({ from, name: callerName, signal }) => {setCall({ isReceivingCall: true, from, name: callerName, signal }); });
	socket.on("updateProgressbarRcv", ({percentage}) => { setProgressprogressbarPercentage(percentage);});	  
  socket.on("updadateoverlaycoordinatesRcv", () => {	 });
  socket.on("clientsnapshotServerRcv", ({type}) => {	
      console.log("Server Received the event=="+type);
  });

	socket.on("updateClientDatatoHostRcv", ({name,pan,latitude,longitude,referenceno}) => {
    
	setClientName(name);
	setClientPAN(pan);
	setClientlatitude(latitude);
	setClientlongitude(longitude);	
  let referencenoobj=referenceno;
  let refno=referencenoobj.referenceno;
  let browsername=referencenoobj.browsername;
	setclient_referenceno(refno);			   
	setclientreferenceno(refno);
  global_crefernceno=refno;
  setclientBrowserName(browsername);

	});

	socket.on("updateUploadProgressbarRcv", ({percentage}) => { setProgressprogressbarPercentage(percentage);});
  socket.on("clientinternetspeedRcv", ({speed}) => { 
      setclientinternetspeed(speed);
      setclientinternetspeedlastupdated(new Date().toLocaleString());
  });

	socket.on("aadharuploadcompletedRcv", () => {
		  setaadharUploadStatus(true);	  
		  setvideoabsolutecontainer(true);
		  setclientVideoHeight("100");
		  setcaptureaadhar(true);
	});

    socket.on("otpvalidationdoneRcv", () => {
      toast.success("OTP has been verified succcessfully.",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
	  });

	  socket.on("aadharuploaddoneRcv", () => { setaadharUploadStatus(true); });
	  socket.on("updatePANStatusRcv", ({status}) => { setUserPANStatus(status); });	  
	  socket.on("processPANStepRcv", () => { setoverlaypanelstatus(true); });
	  socket.on("updateoverlaystatusRcv", (status) => { setoverlaypanelstatus(false); });
	  socket.on("updateClientVideoHeightRcv", () => {
		  setclientVideoHeight("25");
		  setvideoabsolutecontainer(false);
	  });

    socket.on("savedrecordingdataRcv", () => { 	  
      setloaderoverlaystatus(false);  
      setvideouploadcompletestatus(true);
      toast.success("Data submitted successfully and A2Form shown to customer.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
    });
    
    socket.on("photocapturedoneRcv", ({type}) => {
      setphotocapturedonetype(type);
      setphotocapturedonestatus(true);
	  });

	  socket.on("msgRcv", ({ name, msg: value, sender }) => {
		  setMsgRcv({ value, sender });
		  setTimeout(() => { setMsgRcv({});}, 2000);
	  });
	  
	  socket.on("updateUserMedia", ({ type, currentMediaStatus }) => {
	  	if (currentMediaStatus !== null && currentMediaStatus !== []) {
        
		    switch (type) {
			    case "video":
			      setUserVdoStatus(currentMediaStatus);
			      break;
			    
          case "mic":
			       setUserMicStatus(currentMediaStatus);
			      break;
			
          default:
			      setUserMicStatus(currentMediaStatus[0]);
			      setUserVdoStatus(currentMediaStatus[1]);
			      break;
		    }
		  }
	  });
	  
	   Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
        faceapi.nets.faceExpressionNet.loadFromUri('/models'),
	      faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
      ]);
	  
  }, []);
  

  useEffect( async() => {
      if(stopvideorecordingstatus && mediaBlob) {
        saverecordingdata(mediaBlob); 
      }

  }, [mediaBlob]);

  useEffect( async() => {

    if(photocapturedonestatus) {

      setloaderoverlaytext("Downloading "+photocapturedonetype.toLocaleUpperCase());
      const formData=new FormData();		        						
      formData.append("referenceno",client_referenceno);
      formData.append("type",photocapturedonetype);
      const response = await axios.post(API_URL+'clientphotodata/', formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });   
      setloaderoverlaystatus(false);
      clearInterval(photocaptureinterval);
      var responsedata=response.data;
      var data=responsedata.data; 
      if(photocapturedonetype == "pan") {		
        setuserpanImageCSS("show");
        setuserpanImage(data.filename);
        processPANOCR();
        setcapturepan(true);
      }	

      if(photocapturedonetype == "passport") {		
        setuserpassportImageCSS("show");		 
        setuserpassportImage(data.filename);		
        setoverlaypanelstatus(false);
        var overlaystatus=false;          
        socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus});  
        setcapturepassport1(true);
      }	

      if(photocapturedonetype == "passport2") {		
          setuserpassport2ImageCSS("show");		 
          setuserpassport2Image(data.filename);		
          setoverlaypanelstatus(false);
          var overlaystatus=false;          
          socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus});  	
          setcapturepassport2(true);			 
      }	
      if(photocapturedonetype == "signature") {		
        setsignatureImageCSS("show");		 
        setsignatureImage(data.filename);		
        setoverlaypanelstatus(false);
        var overlaystatus=false;          
        socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus}); 
        setcapturesignature(true);			 
      }	

      if(photocapturedonetype == "face") {
        setface_Image(data.filename);
      }
    }
  },[photocapturedonestatus]);
  
  const setCapturedFaceImage =(imagedata) => {	  
	  //if(imagedata !="") setface_Image(imagedata);
  }

  const setManual_FaceMatching =(flag) => {	setface_matching(flag); }
  const enableRearCamera = () => { alert("enable rare Camera"); }  
  
  const changeoverlaystatus=(status)=>{
    setloaderoverlaystatus(status);
  }
  const changeloaderoverlaytext=(text)=>{
    setloaderoverlaytext(text);
  }

  const setHostData = (hostdata) => {

  	if(hostdata !="") {		 

		  let hostname=hostdata.firstname+" "+hostdata.lastname;
		  let referenceno=hostdata.referenceno;		
      let orgid=hostdata.orgid;	

		  let currentdate=new Date();
		  let currenttime=currentdate.getTime();  
		  sethostreferenceno(referenceno);	
      global_hrefernceno=referenceno;
      global_orgid=orgid;
      sethostorgid(orgid);	
  		let socket_id=socket.id;		

	  	if(socket_id == null) {
		  	socket.on('connect', () => {
          sethostreferenceno(global_hrefernceno);
          global_hostsocketid=socket.id;
			  	sessionStorage.setItem("hostsocketid",global_hostsocketid);
				  socket.emit("setreferencenotosocket",{socketid:global_hostsocketid,referenceno:global_hrefernceno,orgid:global_orgid}); 			
			  });
		  }
		  else {		
        global_hostsocketid=socket.id;
			  sessionStorage.setItem("hostsocketid",socket.id);		
			  socket.emit("setreferencenotosocket",{socketid:global_hostsocketid,referenceno:global_hrefernceno,orgid:global_orgid}); 
		  }		
	  }
  }
  
  const answerCall = async () => {

    setCallAccepted(true);    
    setOtherUser(call.from);
    global_clientsocketid=call.from;
    //const peer = new Peer({ initiator: true, trickle: false, stream });		
    const peer = new Peer({ initiator: false, trickle: false, stream : stream, 
      sdpTransform: (sdp) => {
        //const sdp2 = setMediaBitrate(setMediaBitrate(sdp, 'video',video_bandwidth), 'audio',audio_bandwidth);
        const sdp2 =  setMediaBitrate(sdp, 'video',video_bandwidth);
        return sdp2;
      } 
    });	

    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from, userName: name, type: "both",
        myMediaStatus: [myMicStatus, myVdoStatus], });
    });
	
	//peer.on('error',err => console.log('error',err));

  peer.on('error', (err) => {	
    console.log(err.code);
		alert("Connection failed.Please try to connect again.");
		window.location.reload();
		return false;
	});	
	
  peer.on("stream", (currentStream) => {
    userVideo.current.srcObject = currentStream;
  });	
  
  peer.signal(call.signal);
  connectionRef.current = peer;		
  step1();
};

  function wait(delayInMS) {
    return new Promise(resolve => setTimeout(resolve, delayInMS));
  }

  /******************PAN Card Capture***************** */
   var identitytype="";	
   var customer_pan="BQNPC1198P";

   var iterator=1;
   var idType = 0;
   var idTypeA = ["pan"];
   identitytype = idTypeA[0];
   const totalIterations =3;
   var waitInSecs = 0;

  const startVideoRecording =async() => {

    setrecordingstatus(true);
    setvideorecordingside("host");
      /*var options = {frameRate: {max:maxframerate}}
        recorder = new MediaRecorder(userVideo.current.srcObject, options);	
        chunks = [];
        recorder.start();
        recorder.ondataavailable = event => chunks.push(event.data);*/
        await getMediaStream();
        startRecording();
        toast.info("Recording Started.",{theme:'colored',position: "top-center",autoClose:2000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
  }


  const startClientsideVideoRecording =() => {

    setrecordingstatus(true);
    setvideorecordingside("client");
    socket.emit("startclientrecording",{to:otherUser});  
    toast.info("Recording Started.",{theme:'colored',position: "top-center",autoClose:2000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
  }

  const showA2Form=()=>{
    console.log("Entered into A2Form Func");
    socket.emit("saveocrdone",{to:otherUser}); 	
  }
  
  const stopVideoRecording=()=> {	   
	   //recorder.stop(); 
	}
	
	const saveocrdata = async () => {		

    if(videorecordingside == "host") {
      /*recorder.stop();
      recorder.onstop = function(e) {
        recordedBlob = new Blob(chunks, { type: "video/mp4" });
        console.log("Successfully recorded " + recordedBlob.size + " bytes of " +
        recordedBlob.type + " media.");  
        //submitocrdata();
        saverecordingdata();
      }*/
      setstopvideorecording(true);
      stopRecording();
    }

    if(videorecordingside=="client") {
      socket.emit("saveclientrecording",{to:otherUser});  
      setloaderoverlaytext("Data submitting");
      setloaderoverlaystatus(true);  
    }

	}

  const saverecordingdata = async (mediaBlobUrl) => {	

    var saverecordingdataInrtervalcount=0;
    var saverecordingdataInrterval = setInterval(async() => {
    if(socket.connected) {

        clearInterval(saverecordingdataInrterval);
        var blobUrl = URL.createObjectURL(mediaBlobUrl);
        console.log(blobUrl);
      
        const formData=new FormData();								
        formData.append("referenceno",clientreferenceno);			
        formData.append("video_blob",mediaBlobUrl);	

        formData.append("latitude",clientLatitude);
        formData.append("longitude",clientLongitude);
        formData.append("googleaddress",client_address);

        console.log("clientLatitude==="+clientLatitude);
        console.log("clientLongitude==="+clientLongitude);
        console.log("client_address==="+client_address);

        formData.append("facematching",face_matching);
        formData.append("hostreferenceno",hostreferenceno);
        setloaderoverlaytext("Data submitting");
        setloaderoverlaystatus(true);  
        try {
          await axios.post(API_URL+'saverecordingdata/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then((response) => {

            setloaderoverlaystatus(false);  
            var responsedata=response.data;  				
            if(responsedata.status) {			   

              setvideouploadcompletestatus(true);
              socket.emit("saveocrdone",{to:otherUser}); 	 

              toast.success("Data submitted successfully and A2Form shown to customer.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
            }
            else { 
              alert(responsedata.message);
            }		   
          }).catch(err => {			
            console.log("Save Recording Data");
          });  
        
        } catch (err) {
          if (err.response.status === 500) { console.log('There was a problem with the server');} 
          else { console.log(err.response.data.msg);}
        } 
      }
      else {
        saverecordingdataInrtervalcount++;
        if(saverecordingdataInrtervalcount == 5) {
          clearInterval(saverecordingdataInrterval);
          toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
        }
      }
      
    },500);
  }

  const captureclientface=()=> {

    var facecaptureInrtervalcount=0;
    var facecaptureInrterval = setInterval(() => {

      if(socket.connected) {
        
          clearInterval(facecaptureInrterval);
          identitytype="face";		
          setcurrentprocesstype(identitytype);    
          if(CLIENT_BROWSERS.indexOf(clientBrowserName)  != -1) {
            clientsnapShot();
          } 
          else {
            console.log("entered into else");
            captureFace();
          }

        }
        else {
          facecaptureInrtervalcount++;
          if(facecaptureInrtervalcount == 5) {
            clearInterval(facecaptureInrterval);
            toast.error("Please Try Again",{theme:'colored',position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
          }
        }

    },500);

  }
  
  const submitocrdata = async () => {		

    var data=[];		 
    var panobj={};	 
    panobj['status']="Verified";
    panobj['number']=clientPAN;
    panobj['image']=userpanImage;		
    panobj['name']=clientName;
    panobj['dob']="";
    panobj['address']="";   	 
    panobj['type']="PAN";			 
    data.push(panobj); 

   var passportobj={};
   passportobj['status']="Verified";
   passportobj['number']='';
   passportobj['image']=userpassportImage;	
   passportobj['image2']=userpassport2Image;
   
   passportobj['name']=clientName;
   passportobj['dob']="";				
   passportobj['address']="";
   passportobj['type']="PASSPORT";	
   data.push(passportobj);
   
    const formData=new FormData();		
    formData.append("data",JSON.stringify(data));							
    formData.append("referenceno",clientreferenceno);			
    formData.append("video_blob",recordedBlob);	
    formData.append("latitude",clientLatitude);
    formData.append("longitude",clientLongitude);
    formData.append("facematching",face_matching);
    formData.append("signature",signatureImage);
    var hostrefno=sessionStorage.getItem("hostreferenceno");	
    formData.append("hostreferenceno",hostrefno);
    
    try {
      await axios.post(API_URL+'saveocr/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      }).then((response) => {
         var responsedata=response.data;  				
         if(responsedata.status) {			   
          socket.emit("saveocrdone",{to:otherUser}); 	   
          toast.success("Data submitted successfully and A2Form shown to customer.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
         }
         else { 
         alert(responsedata.message);
         }		   
       
      }).catch(err => {			

       if(ocrsubmitcounter < setocrsubmitattempts) {						
         var counter=ocrsubmitcounter;								
         setocrsubmitcounter(counter+1);						
         submitocrdata();
       }
       else {
         alert(err +".Pease try again");
       }
     });  
     
    } catch (err) {
      if (err.response.status === 500) { console.log('There was a problem with the server');} 
      else { console.log(err.response.data.msg);}
    } 

  }
 
  function setIntervalX(callback) 
  {
         var intervalID = window.setInterval(function () {
             if ( waitInSecs > 0)
             {
                 waitInSecs -= 2;
                 //present = 0;
                 return;
             }
             
           callback(iterator) ;
           if ((iterator+ 1) > totalIterations) {
               if ((idType+1) >= idTypeA.length )
               {
                 window.clearInterval(intervalID);
                 $("#messagelayer").html("Please remove your card. OCR Processing is on....");
             }
             else
             {
                 identitytype = idTypeA[++idType];
                 var message="Please show your <b>"+idTypeA[idType].toUpperCase()+ " </b> in the specified area and hold it";
                 $("#messagelayer").html(message);
                 waitInSecs = 6;
                 //overlaysetup();
                 iterator = 1;
                 //imgData = null;
             }
           }
           else iterator++;
           
       }, 500);

     }
	 
  function step2() {

      if(PAN_CAPTURE ==1 || PASSPORT1_CAPTURE ==1 || PASSPORT2_CAPTURE ==1 || SIGNATURE_CAPTURE ==1) 
      if(PAN_CAPTURE ==2 || PASSPORT1_CAPTURE ==2 || PASSPORT2_CAPTURE ==2 || SIGNATURE_CAPTURE ==2) clientsnapShot();
      if(PAN_CAPTURE ==3 || PASSPORT1_CAPTURE ==3 || PASSPORT2_CAPTURE ==3 || SIGNATURE_CAPTURE ==3) {
        snapShot();
        clientsnapShot();
      }
  }
	
  function disconnecthostsocket() {

      console.log("inside disconnecthostsocket");
      console.log("hostreferenceno before==="+hostreferenceno);
      console.log("clientreferenceno before=="+clientreferenceno);

      global_hrefernceno=hostreferenceno;
      global_crefernceno=clientreferenceno;
      socket.disconnect();
      socket.connect();
      sethostreferenceno(global_hrefernceno);
      setclientreferenceno(global_crefernceno);

      console.log("afeter connect==="+global_hrefernceno);
      console.log("after connect=="+global_crefernceno);
  }

  function inita2formesign() {
    setvideouploadcompletestatus(true);
    socket.emit("saveocrdone",{to:otherUser}); 	 
  }

  function clientsidesnapshot() {
     clientsnapShot();
  }

  function hostsidesnapshot() {
    snapShot();
  }
	function processPANOCR() {		

     setoverlaypanelstatus(false);  
		 setclientpanocrstatus(true);		 
		 var overlaystatus=false;          
		 socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus});  
	 }
	
   async function clientsnapShot() {

      console.log("Snapshot event triggered");

      setloaderoverlaytext("Capturing "+currentprocesstype.toLocaleUpperCase());
      setloaderoverlaystatus(true);  
      setphotocapturedonestatus(false);
  
      try {
        socket.emit("clientsnapshot",{to:otherUser,type:currentprocesstype,me:global_hostsocketid}); 
      }
      catch(err) {
          console.log(err.message);
      }

      var intervalcounter=0;
      photocaptureinterval = setInterval(() => {

        intervalcounter++;
        setloaderoverlaytext("Capturing "+currentprocesstype.toLocaleUpperCase()+" ..."+intervalcounter);
        if(intervalcounter ==20) {
           clearInterval(photocaptureinterval);
           setloaderoverlaystatus(false); 
           toast.info("Could not capture "+currentprocesstype.toLocaleUpperCase()+" . Please try again",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				  
        } 

      },1000);

   }
   
   async function snapShot() {	

      var videoElement =document.getElementById("localVideo");
      var canvaslayer=currentprocesstype+"_layer";
      var canvas=document.querySelector("#"+canvaslayer+" canvas");
      var img=document.querySelector("#"+canvaslayer+" img");
      var ctx = canvas.getContext('2d');

  		var videoWidth=$("#localVideo").width();
	  	var videoHeight=$("#localVideo").height();
		 
		  var imgWidth=videoWidth;
		  var imgHeight=videoHeight;
		
      canvas.width = videoWidth;
      canvas.height = videoHeight;
         //ctx.clearRect(0,0,videoWidth,videoHeight);			
		     //ctx.filter = 'grayscale(100%) contrast(110%) brightness(150%)';
		     ctx.filter = 'contrast(120%) brightness(150%)';
         await ctx.drawImage(videoElement,0, 0,videoWidth,videoHeight,0,0,videoWidth,videoHeight);
         //var present = checkCardPresense(canvas,imgData1);
		 
  		 if(currentprocesstype == "pan") {			 
	  		setuserpanImageCSS("show");
		  	setuserpanImage(canvas.toDataURL());
			  processPANOCR();
			  setcapturepan(true);
        uploadPhoto(currentprocesstype,canvas.toDataURL());
		  }		 
		 
		 if(currentprocesstype == "passport") {		

       setuserpassportImageCSS("show");		 
			 setuserpassportImage(canvas.toDataURL());		
			 setoverlaypanelstatus(false);
        var overlaystatus=false;          
			 socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus});  
			 setcapturepassport1(true);
       uploadPhoto(currentprocesstype,canvas.toDataURL());
		 }	

		 if(currentprocesstype == "passport2") {		
        setuserpassport2ImageCSS("show");		 
			  setuserpassport2Image(canvas.toDataURL());		
			  setoverlaypanelstatus(false);
	      var overlaystatus=false;          
			  socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus});  	
        setcapturepassport2(true);		
        uploadPhoto(currentprocesstype,canvas.toDataURL());	 
		 }	
		 
		 if(currentprocesstype == "signature") {		
        setsignatureImageCSS("show");		 
			  setsignatureImage(canvas.toDataURL());		
			  setoverlaypanelstatus(false);
	      var overlaystatus=false;          
			  socket.emit("updateoverlaystatus",{to:otherUser,overlaystatus}); 
        setcapturesignature(true);	
        uploadPhoto(currentprocesstype,canvas.toDataURL());		 
		}			 

    if(currentprocesstype == "face") {
      uploadPhoto(currentprocesstype,canvas.toDataURL());		 
    }			
}

async function captureFace() {	

  var videoElement =document.getElementById("localVideo");
  var canvas=document.getElementById("facecanvas");
  var ctx = canvas.getContext('2d');
  var videoWidth=$("#localVideo").width();
  var videoHeight=$("#localVideo").height();
  var imgWidth=videoWidth;
  var imgHeight=videoHeight;
  canvas.width = videoWidth ;
  canvas.height = videoHeight;
  ctx.filter = 'contrast(120%) brightness(150%)';
  await ctx.drawImage(videoElement,0, 0,videoWidth,videoHeight,0,0,videoWidth,videoHeight);
  uploadPhoto('face',canvas.toDataURL());		 
}

const uploadPhoto = async(type,imagedata) => {	
  /**********/
  const formData=new FormData();								
  formData.append("referenceno",client_referenceno);			  
  formData.append("type",type);
  formData.append("imagedata",imagedata);
  try {
      await axios.post(API_URL+'savephoto/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      }).then((response) => {
         var responsedata=response.data;  				
         if(responsedata.status) {		
            toast.success( type.toUpperCase()+" captured successfully.",{theme:'colored',position: "top-center",autoClose: 5000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				  
            if(aadharUploadStatus) {
              setphotocapturedonetype(type);
              setphotocapturedonestatus(true);
            }
         }
         else { 
            alert(responsedata.message);
         }		   
      }).catch(err => {			

      });  
    } catch (err) {
      if (err.response.status === 500) { console.log('There was a problem with the server');} 
      else { console.log(err.response.data.msg);}
    }
    /*********/
}

function processSnapShot(img) 
{              
    work(img);
    async function work(im) {
    var worker =createWorker({
      //logger: m => console.log(m)
    });

    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    var result = await worker.recognize(im);
    //results[it-1] = result.data;
    console.log(result.data);
    await worker.terminate();
    console.log("=====OCR Data ENDS=====");
    //console.log("idType in work==="+idType);
           
               var regularexp="";
               var parts = "";
               if(idType ==0)
               {   
                   parts = customer_pan.match(/.{1,1}/g);
               }
             
               var new_value = parts.join("|");               
               regularexp= new RegExp(new_value,"ig");  
               
               if ( result.data.lines != undefined)
               for(var j = result.data.lines.length - 1; j >= 0  ; j--) 
                   {
                   for(var k = 0; k < result.data.lines[j].words.length  ; k++) 
                       {
                           if ( result.data.lines[j].words[k])
                           {
                               var resLength = 0;
                               var result1 = result.data.lines[j].words[k].text.match(regularexp);
                               if (result1 != null) resLength = result1.length;
                               if ( resLength > 6 )
                               console.log("j..."+j+"--"+resLength+"...."+result1);
                             }
                       }
                       
                   }
                           
           }
       }
       
     /*********************/

  const step1= () => { 

	  socket.emit("updadateoverlaycoordinates", { to:otherUser });
    setoverlaypanelstatus(true);
	  setclientpanocrstatus(false);
    socket.emit("processpanstep", {to: otherUser});
    identitytype="pan";	
	  setUserPANStatus(false);
	  var panstatus=false;
    socket.emit("updatePANStatus", {to: otherUser,status:panstatus}); 	
	  setvideoabsolutecontainer(true);
	  setcurrentprocesstype("pan");
  }
  
  const processfacecapturestep=()=>{
	  identitytype="face";		  
	  setcurrentprocesstype("face");
    if(CLIENT_BROWSERS.indexOf(clientBrowserName)  !== -1) setTimeout(() => { captureFace();}, 2000);
  }

  const processpassportstep = () => {
	  setoverlaypanelstatus(true);
	  socket.emit("processpanstep", {to: otherUser});
	  identitytype="passport";		  
	  setvideoabsolutecontainer(true);
	  setUserPassportStatus(false);	
	  setcurrentprocesstype("passport");
  }
  
  const processpassport2step = () => {
	  setoverlaypanelstatus(true);
	  socket.emit("processpanstep", {to: otherUser});
	  identitytype="passport2";		  
	  setvideoabsolutecontainer(true);
	  setUserPassport2Status(false);	
	  setcurrentprocesstype("passport2");
  }

   const processsignaturestep = () => {	  
	  setoverlaypanelstatus(true);
	  socket.emit("processpanstep", {to: otherUser});
	  identitytype="signature";		  
	  setvideoabsolutecontainer(true);	
	  setcurrentprocesstype("signature");
  }

  const processNextStep= (currentStep) => {
	  
	  if(currentStep === "firstStep") {
		  
		  setUserPANStatus(true);    
		  var pan_status=true;
			processfacecapturestep();
      socket.emit("updateClientVideoHeight", {to: otherUser});  
			socket.emit("updatePANStatus",{to:otherUser,status:pan_status}); 
			toast.info("Aadhar verification form shown to clinet.",{theme:'colored',position: "top-center",autoClose:2000,hideProgressBar: false,closeOnClick:true,pauseOnHover: true,draggable: true,progress: undefined});				
	  }

	  if(currentStep === "secondStep") { processpassportstep(); }
	  if(currentStep === "thirdStep") { processpassport2step();}
	  if(currentStep === "fourthStep") { processsignaturestep();}
  }
  
  const aadharuploadcompleted = () => {	  
	  socket.emit("aadharuploadcompleted",{to:otherUser});
	  setaadharUploadStatus(true);	  
	  setvideoabsolutecontainer(true);
    setclientVideoHeight("100");
  }
  
  const setClientDetails = (data) => {

    setUserName(data.name);
    setClientName(data.name);
    setName(data.name);
    setClientPAN(data.pan);
    setClientlatitude(data.latitude);
    setClientlongitude(data.longitude);	
  }

  const updateVideo = () => {
    setMyVdoStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "video", currentMediaStatus: !currentStatus,
      });
      stream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const updateMic = () => {
    setMyMicStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "mic", currentMediaStatus: !currentStatus,
      });

     stream.getAudioTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const leaveCall = () => {	  
    setCallEnded(true);
    connectionRef.current.destroy();
    socket.emit("endCall", { id: otherUser,host_referenceno:hostreferenceno,host_socketid:socket.id});	
	  window.location.reload();
  };
  
  const declineCall =() => {	  
    socket.emit("declineCall", { id: otherUser,host_referenceno:hostreferenceno,host_socketid:socket.id});	
	  window.location.reload();
  }
  
  const hostlogout = () => {
    socket.emit("hostlogout",{host_referenceno:hostreferenceno});
  	sessionStorage.setItem("hostsocketid",null);
	  sessionStorage.getItem("hostdata",null);	
    window.location.reload();
  }

  const leaveCall1 = () => { socket.emit("endCall", { id: otherUser,host_referenceno:hostreferenceno }); };
  const sendMsg = (value) => {
    
    socket.emit("msgUser", { name, to: otherUser, msg: value, sender: name });
    let msg = {};
    msg.msg = value;
    msg.type = "sent";
    msg.timestamp = Date.now();
    msg.sender = name;
    setChat([...chat, msg]);
  };

  return (
    <HostVideoContext.Provider
      value={{ call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, leaveCall, setClientDetails,
        answerCall, sendMsg, msgRcv, chat, setChat, setMsgRcv, setOtherUser, leaveCall1, userName, myVdoStatus,
        setMyVdoStatus, userVdoStatus, setUserVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic,
        clientName, clientPAN, clientLatitude, clientLongitude, client_address,userPANStatus,progressbarPercentage,
        clientVideoHeight,currentTab,otherUser,aadharUploadStatus,curentcallerType,hostcallerType,clientcallerType,
        step1,step2,processpan,overlaypanelstatus,userpanImage,processPANOCR,clientpanocrstatus,processNextStep,
        userpassportImage,userPasssportStatus,videoabsolutecontainer,processpassportstep,
        saveocrdata,aadharuploadcompleted,overlaywidth,overlayheight,overlaytop,overlayleft,client_referenceno,
        setCapturedFaceImage,setManual_FaceMatching,stopRecording,userpanImageCSS,
        userpassportImageCSS,declineCall,
        processpassport2step,userpassport2Image,userpassport2ImageCSS,userPasssport2Status,
        processsignaturestep,signatureImage,signatureImageCSS,capturepan,captureaadhar,capturepassport1,
        capturepassport2,capturesignature,enableRearCamera,setHostData,hostlogout,startVideoRecording,
        recordingstatus,loaderoverlaytext,loaderoverlaystatus,captureclientface,face_Image,
        changeoverlaystatus,changeloaderoverlaytext,hostsidesnapshot,clientsidesnapshot,mediaBlob,startRecording,stopRecording,stopVideoRecording,startClientsideVideoRecording,clientBrowserName,videouploadcompletestatus,showA2Form,clientinternetspeed,clientinternetspeedlastupdated,disconnecthostsocket,inita2formesign
      }}
    >
      {children}
    </HostVideoContext.Provider>
  );
};
export default HostVideoState;
