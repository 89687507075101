import { useEffect } from "react";
import React from "react";
class Home extends React.Component {
  
  render() {
      return (
		<div>
			<h1>Home Page</h1>
		</div>
      );
  }
}

export default Home;
