import React, {useCallback,useContext,useState,useEffect} from 'react';
import { useParams } from 'react-router';
import HostVideoContext from "../HostVideoState/HostVideoContext";
import { Row,Col,Card,Button} from 'react-bootstrap';
import panImage from "../../pan_demo.png";
import '../../Config.js';
import axios from 'axios';
import "../HostVideo/HostVideo.css";
import zoombtn from "../../zoom-in.png";
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const CAPTURED_IMAGE_WIDTH=global.CAPTURED_IMAGE_WIDTH;
const CAPTURED_IMAGE_HEIGHT=global.CAPTURED_IMAGE_HEIGHT;
const CLIENT_BROWSERS=global.CLIENT_BROWSERS;

const PASSPORT1_SKIP=global.PASSPORT1_SKIP;
const PASSPORT2_SKIP=global.PASSPORT2_SKIP;

const PASSPORT1_HELP_BTN=global.PASSPORT1_HELP_BTN;
const PASSPORT1_HELP_TXT=global.PASSPORT1_HELP_TXT;

const PASSPORT2_HELP_BTN=global.PASSPORT2_HELP_BTN;
const PASSPORT2_HELP_TXT=global.PASSPORT2_HELP_TXT;

const SIGNATURE_HELP_BTN=global.SIGNATURE_HELP_BTN;
const SIGNATURE_HELP_TXT=global.SIGNATURE_HELP_TXT;

function PASSPORT({type}) {
	
	const {
		callAccepted,processpassportstep,step2,userpassportImage,userpassportImageCSS,
		processpassport2step,userpassport2Image,userpassport2ImageCSS,
		processsignaturestep,signatureImage,signatureImageCSS,enablerarecamera,hostsidesnapshot,clientsidesnapshot,clientBrowserName,videouploadcompletestatus,showA2Form
	} = useContext(HostVideoContext);	
	
	const [passport1flag, setpassport1flag] = useState(false);
	const [passport2flag, setpassport2flag] = useState(false);
	const [signatureflag, setsignatureflag] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const [isOpen,setisOpen] = useState(false);
	const [client_browserName, setclient_browserName] = useState("");

	useEffect(() => {   
		setclient_browserName(clientBrowserName)
	},[clientBrowserName]);
	
    const handleImgLoad = useCallback(() => {
		setIsZoomed(true)
	}, [])
	
	const handleZoomChange = useCallback(shouldZoom => {
		setIsZoomed(shouldZoom)
	}, [])

	const openLightBox = () => {
		setisOpen(true);
	 }
	
	 const closeLightBox=()=> {
		setisOpen(false);
	 }

	useEffect(() => {   
	 
		if(type == "passport") {
			setpassport1flag(true); 
			setpassport2flag(false);
			setsignatureflag(false);
		}
		else if(type == "passport2"){
			setpassport1flag(false); 
			setpassport2flag(true);
			setsignatureflag(false);
			
		}
		else if(type == "signature") {
			setpassport1flag(false); 
			setpassport2flag(false);
			setsignatureflag(true);
		}			
	},[type]);
  
    return (
        <div>
			{passport1flag && (
				<div>
					<Row>
						<Col md='12' className="mgt-10 align-right"> 
							<div className="mgt-10">
								<input type="button" className="btn btn-success float-right" onClick={hostsidesnapshot} value="Host Capture"/>
								{ CLIENT_BROWSERS.indexOf(client_browserName) != 1 &&(
									<input type="button" className="btn btn-danger float-left" onClick={clientsidesnapshot} value="Client Capture"/>
								)}					
							</div>
						</Col>
						<Col md='12' className="mgt-20 align-center"> 
							<div id='passport_layer' className="pan_image">
								<canvas style={{display:'none'}}></canvas>  
								{userpassportImage && (
									<div>
										<img className="captured_image" src={userpassportImage} onClick={openLightBox} width={CAPTURED_IMAGE_WIDTH} height={CAPTURED_IMAGE_HEIGHT}/>
									</div>
								)}
								
								{isOpen && (
									<Lightbox  image={userpassportImage}  onClose={closeLightBox} title="PASSPORT"/>
								)}
								
							</div>
						</Col>						
					</Row>  
				</div>
			)}
			
			{passport2flag && (
				<div>
					<Row>
						<Col md='12' className="mgt-10 align-right"> 
							<div className="mgt-10">
								<input type="button" className="btn btn-success float-right" onClick={hostsidesnapshot} value="Host Capture"/>
								{ CLIENT_BROWSERS.indexOf(client_browserName) != 1 &&(
									<input type="button" className="btn btn-danger float-left" onClick={clientsidesnapshot} value="Client Capture"/>
								)}
							</div>
						</Col>
						<Col md='12' className="mgt-20 align-center"> 
							<div id='passport2_layer' className="pan_image">
								<canvas style={{display:'none'}}></canvas>  

								{userpassport2Image && (									
									<div>
										<img className="captured_image" src={userpassport2Image} onClick={openLightBox} width={CAPTURED_IMAGE_WIDTH} height={CAPTURED_IMAGE_HEIGHT} />
									</div>
								)}
								
								{isOpen && (
									<Lightbox  image={userpassport2Image}  onClose={closeLightBox} title="PASSPORT"/>
								)}	
								
							</div>
						</Col>						
					</Row> 
				</div>
			)}
			
			
			{signatureflag && (
				<div>
					
					{videouploadcompletestatus &&(
						<input 
							type="button"									
							className="btn btn-danger float-right" 
							value='Show A2Form' onClick={showA2Form} />)			
					}

					{!videouploadcompletestatus &&(
					
						<div>

						<Row>
							<Col md='12' className="mgt-10 align-right"> 				
								<div className="mgt-10">
									<input type="button" className="btn btn-success float-right" onClick={hostsidesnapshot} value="Host Capture"/>
									{ CLIENT_BROWSERS.indexOf(client_browserName) != 1 &&(
										<input type="button" className="btn btn-danger float-left" onClick={clientsidesnapshot} value="Client Capture"/>
									)}

									
								</div>
							</Col>
							
							<Col md='12' className="mgt-20 align-center"> 
								<div id='signature_layer' className="pan_image">
									<canvas style={{display:'none'}}></canvas>  
									{signatureImage && (									
										<div>
											<img className="captured_image" src={signatureImage} onClick={openLightBox} width={CAPTURED_IMAGE_WIDTH} height={CAPTURED_IMAGE_HEIGHT} />
										</div>
									)}
									
									{isOpen && (
										<Lightbox  image={signatureImage}  onClose={closeLightBox} title="Signature"/>
									)}	
								</div>
							</Col>						
						</Row> 
					</div>
				)}

				</div>
			)}
			
        </div>
    )
}
export default PASSPORT;