import React, { useContext, useEffect, useState, useRef,useLayoutEffect,useCallback} from "react";
import ClientVideoContext from "../ClientVideoState/ClientVideoContext";
import { Row,Col,Card,Button} from 'react-bootstrap';
import "./ClientVideo.css";
import { Input, notification, Avatar } from "antd";
import VideoIcon from "../../assests/video.svg";
import VideoOff from "../../assests/video-off.svg";
import {  MessageOutlined } from "@ant-design/icons";
import { socket } from "../ClientVideoState/ClientVideoState";
import FileUpload from '../FileUpload';
import AadharOTPVerification from '../AadharOTPVerification';
import ClientHeader from "../ClientHeader/ClientHeader";
import LoadingOverlay from 'react-loading-overlay-ts';
import A2Form from "../A2Form/A2Form";
import Message from "../Message";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactInternetSpeedMeter } from 'react-internet-meter'

const { Search } = Input;
var key='';
var value='';
const params = {}
window.location.search.substr(1).split('&').forEach(pair => {
  [key,value] = pair.split('=')
  params[key] = value
})

const AADHAR_VALIDATION=global.AADHAR_VALIDATION;
const SPEEDTEST_IMAGE=global.SPEEDTEST_IMAGE;
const SPEEDTEST_PINGINTERVAL=global.SPEEDTEST_PINGINTERVAL;
const SPEEDTEST_THRESHOLD=global.SPEEDTEST_THRESHOLD;
//const DISCONNECT_BTN=global.DISCONNECT_BTN;
const DISCONNECT_BTN=false;

const ClientVideo = ({clientDataObj}) => {
		
	const { callAccepted, myVideo, userVideo, stream, callEnded, setClientDetails, sendMsg: sendMsgFunc, msgRcv, 
		chat, setChat, myVdoStatus, userVdoStatus, userPANStatus, clientVideoHeight, aadharUploadStatus,
		overlaypanelstatus, videoabsolutecontainer, overlaywidth,overlayheight,overlaytop,overlayleft,initcall,a2formstatus,capturedImage,facecapturestatus,endcallMessage,otherUser,disconnectclientsocket
	} = useContext(ClientVideoContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [client_VideoHeight,setclient_VideoHeight] = useState(clientVideoHeight);
  const [isOverlayActive,setOverlayActive] = useState(false);
  const [internetspeed,setinternetspeed] = useState('');
  const [internetspeedlastupdated,setinternetspeedlastupdated] = useState('');

  useEffect(() => { setClientDetails(clientDataObj); },[]);
  useEffect(() => { setclient_VideoHeight(clientVideoHeight);},[clientVideoHeight]);
  
  const handleButtonClicked = useCallback(() => {
    setOverlayActive(value => !value)
  }, [])
  
  useEffect(() => {   
	 if(callAccepted) {
		handleButtonClicked(); 
	 }
  },[callAccepted]);
  
   useEffect(() => {   
	 if(initcall) {
		  handleButtonClicked(); 
	 }
  },[initcall]);
  
  socket.on("msgRcv", ({ name, msg: value, sender }) => {
    let msg = {};
    msg.msg = value;
    msg.type = "rcv";
    msg.sender = sender;
    msg.timestamp = Date.now();
    setChat([...chat, msg]);    
  });

  const dummy = useRef();

  useEffect(() => { if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" }); }, [chat]);

  const showModal = (showVal) => { setIsModalVisible(showVal); };

  useEffect(() => {
    if (msgRcv.value && !isModalVisible) {
      notification.open({
        message: "",
        description: `${msgRcv.sender}: ${msgRcv.value}`,
        icon: <MessageOutlined style={{ color: "#108ee9" }} />,
      });
    }
  }, [msgRcv]);

  const setwifiSpeed=(speed)=> {
      setinternetspeed(speed);
      setinternetspeedlastupdated(new Date().toLocaleString());
  }

  useEffect(() => {
    console.log("Internet speed changed");
    console.log(internetspeed);
    socket.emit("clientinternetspeed",{to:otherUser,speed:internetspeed});
  }, [internetspeed]);


  
  return (  
    <div>	
        <ReactInternetSpeedMeter  
            txtSubHeading="Internet is too slow"
            outputType="console"
            customClassName={null}
            txtMainHeading="Opps..." 
            pingInterval={SPEEDTEST_PINGINTERVAL} // milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={SPEEDTEST_THRESHOLD}
            imageUrl={SPEEDTEST_IMAGE}
            downloadSize="1781287"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is slow ${speed}`)}
            callbackFunctionOnNetworkTest={(speed)=>setwifiSpeed(speed)}
      />

		<LoadingOverlay active={isOverlayActive} spinner text='Connecting...'>		
			<ClientHeader 
        orgObj={clientDataObj}  
        internetspeed={internetspeed}
        internetspeedlastupdated={internetspeedlastupdated}
      />         
			  {stream ? (
				<div className={ videoabsolutecontainer ? 'video-container-absolute' : ''} id="video2" >
					<div className="align-center client-call-window">
						
					{callAccepted && overlaypanelstatus && (                
							<canvas className="overlay_client_outer" id="overlay_client_outer" style={{width:overlaywidth+"px",height:overlayheight+"px",top:overlaytop+"px",left:overlayleft+"px"}}></canvas>
					)}
					
          {!callEnded  && (
					<video playsInline muted ref={myVideo} id="client_video" autoPlay className="video-active"
							height={'100%'} style={{ opacity: `${myVdoStatus ? "1" : "0"}` }} />	
          )}
						
				</div>
					
					  {userPANStatus && facecapturestatus && !callEnded && userVideo &&  !aadharUploadStatus && AADHAR_VALIDATION =="OFFLINEUPLOAD" &&(
						<FileUpload />
					  )}
					  
            {userPANStatus && facecapturestatus && !callEnded && userVideo &&  !aadharUploadStatus && AADHAR_VALIDATION =="OTP" &&(
					  	<AadharOTPVerification />
					  )}

					{!callEnded && userVideo &&  a2formstatus && (
						<A2Form />
					)}

          {DISCONNECT_BTN && (
            <div class="disconnectlayer">
              <input type="button" className="btn btn-success float-right" onClick={disconnectclientsocket} value="Disconnect Socket" />
            </div>
          )}

					  
					  <div id='canvas_layer' className="pan_image">							
						  <canvas width = "450"  height = "900" style={{display:'none'}}></canvas>  
							<img src={capturedImage} width="450" style={{display:'none'}} height="900" />								
            </div>

			</div>) : ( <div className="bouncing-loader"> <div></div> </div> )}    
			

      {!callEnded  && (
				<video id="host_video" playsInline ref={userVideo} autoPlay className="video-active"
					style={{ opacity: `${userVdoStatus ? "1" : "0"}`, }} /> 
      )}

      {callEnded && (
            <div>
              <div className="pd-20">
                <Card>
                  <Card.Header>Info</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md='12' className="mgt-10 align-center"> 
                        <label className='form-label'>Thank You.</label>
                      </Col>              
                    </Row>
                  </Card.Body>
                </Card> 
              </div>
            </div>            
        )}

      {endcallMessage &&(
        <Message msg={"Call Ended"} />
      )}
      
		</LoadingOverlay>			
		<ToastContainer />
    </div>
  );
};

export default ClientVideo;
