import React, { useContext, useEffect, useState, useRef,useLayoutEffect } from "react";
import "./HostVideo.css";
import {Input, notification, Avatar } from "antd";
import VideoIcon from "../../assests/video.svg";
import VideoOff from "../../assests/video-off.svg";
import userImage from "../../user.png";
import {UserOutlined, MessageOutlined } from "@ant-design/icons";
import { socket } from "../HostVideoState/HostVideoState";
import HostVideoContext from "../HostVideoState/HostVideoContext";
import { Row,Col} from 'react-bootstrap';
import AadharData from '../AadharData';
import PAN from "../PAN/PAN";
import PASSPORT from "../PASSPORT/PASSPORT";
import ProcessInstructions from "../ProcessInstructions/ProcessInstructions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactInternetSpeedMeter } from 'react-internet-meter'

const { Search } = Input;
const SERVER_URL=global.CONFIG_SERVER_URL;
const API_URL=global.CONFIG_API_URL;
const PASSPORT1_SKIP=global.PASSPORT1_SKIP;
const PASSPORT2_SKIP=global.PASSPORT2_SKIP;

const SPEEDTEST_IMAGE=global.SPEEDTEST_IMAGE;
const SPEEDTEST_PINGINTERVAL=global.SPEEDTEST_PINGINTERVAL;
const SPEEDTEST_THRESHOLD=global.SPEEDTEST_THRESHOLD;

const HostVideo = ({hostdata}) => {

  const { call, callAccepted, myVideo, userVideo, stream, name, setName, callEnded, me, setClientDetails,
    leaveCall, answerCall, sendMsg: sendMsgFunc, msgRcv, chat, setChat, userName, myVdoStatus,
    userVdoStatus, updateVideo, myMicStatus, userMicStatus, updateMic, clientName, clientPAN,
    clientLatitude, clientLongitude, client_address,userPANStatus,progressbarPercentage,clientVideoHeight,currentTab,aadharUploadStatus,curentcallerType,processpan,overlaypanelstatus,processNextStep,videoabsolutecontainer,saveocrdata,overlaywidth,overlayheight,overlaytop,overlayleft
	,capturepan,captureaadhar,capturepassport1,capturepassport2,capturesignature,clientinternetspeed,clientinternetspeedlastupdated
  } = useContext(HostVideoContext);

  const [sendMsg, setSendMsg] = useState("");
  const [host_flag, setHostflag] = useState(false);
  const [client_flag, setClientflag] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nextBtnStatus,setnextBtnStatus]=useState(true);  
  const [nextBtnDisplay,setnextBtnDisplay]=useState("none");
  const [skipBtn,setskipBtn]=useState(false);

  const [internetspeed,setinternetspeed] = useState('');
  const [internetspeedlastupdated,setinternetspeedlastupdated] = useState('');
    
  const notify = () => toast("Wow so easy!");
  const firstComponent = () => {
    return (<PAN/>)    
  }

  const secondComponent = () => {
    return (<AadharData/>)    
  }
	
  const thirdComponent = () => {
    return (<PASSPORT type="passport" />)    
  }
  
  const fourthComponent = () => {
    return (<PASSPORT type="passport2" />)    
  }
  
  const finalComponent = () => {
    return (<PASSPORT type="signature" />)    
  }

  const [steps, setSteps] = useState([
    { key: 'firstStep', label: 'PAN', isDone: true, component: firstComponent},
    { key: 'secondStep', label: 'Aadhar', isDone: false, component: secondComponent},
	{ key: 'thirdStep', label: 'Passport-1', isDone: false, component: thirdComponent},
	{ key: 'fourthStep', label: 'Passport-2', isDone: false, component: fourthComponent},
    { key: 'finalStep', label: 'Signature', isDone: false, component: finalComponent}
  ]);

  const [activeStep, setActiveStep] = useState(steps[currentTab]);  
  const handleNext = () => {
	
	setnextBtnDisplay("none");
    if (steps[steps.length - 1].key === activeStep.key) {		
	  saveocrdata();
      return;
    }
    const index = steps.findIndex(x => x.key === activeStep.key);
	if((index == 1 && PASSPORT1_SKIP) || (index == 2 && PASSPORT2_SKIP)) setskipBtn(true);
	else setskipBtn(false);

    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = true;
      return x;
    }))
    setActiveStep(steps[index + 1]);	
	processNextStep(activeStep.key);
  }

  const handleBack = () => {
	  
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;

    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }))
    setActiveStep(steps[index - 1]);
  }

  socket.on("msgRcv", ({ name, msg: value, sender }) => {
    
    let msg = {};
    msg.msg = value;
    msg.type = "rcv";
    msg.sender = sender;
    msg.timestamp = Date.now();
    setChat([...chat, msg]);    
  });

  const dummy = useRef();

  useEffect(() => {
    if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [chat]);


  useEffect(() => {	  
    if(capturepan) {		
		setnextBtnDisplay("block");
	}
  }, [capturepan]);
  
  useEffect(() => {	  
    if(captureaadhar) {
		setnextBtnDisplay("block");
	}
  }, [captureaadhar]);

  useEffect(() => {	  
    if(capturepassport1) {
		setnextBtnDisplay("block");
	}
  }, [capturepassport1]);
  
  useEffect(() => {	  
    if(capturepassport2) {
		setnextBtnDisplay("block");
	}
  }, [capturepassport2]);

  useEffect(() => {	  
    if(capturesignature) {
		setnextBtnDisplay("block");
	}
  }, [capturesignature]);

  const showModal = (showVal) => {
    setIsModalVisible(showVal);
  };

  const onSearch = (value) => {
    if (value && value.length) sendMsgFunc(value);
    setSendMsg("");
  };

  useEffect(() => {
    if (msgRcv.value && !isModalVisible) {
      notification.open({
        message: "",
        description: `${msgRcv.sender}: ${msgRcv.value}`,
        icon: <MessageOutlined style={{ color: "#108ee9" }} />,
      });
    }
  }, [msgRcv]);


  useEffect(() => {
    setActiveStep(steps[currentTab])
  }, [currentTab]);

  const setwifiSpeed=(speed)=> {
	setinternetspeed(speed);
	setinternetspeedlastupdated(new Date().toLocaleString());
  }

  return (
    <div>      
		
		<ReactInternetSpeedMeter  
				txtSubHeading="Internet is too slow"
				outputType="console"
				customClassName={null}
				txtMainHeading="Opps..." 
				pingInterval={SPEEDTEST_PINGINTERVAL} // milliseconds 
				thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
				threshold={SPEEDTEST_THRESHOLD}
				imageUrl={SPEEDTEST_IMAGE}
				downloadSize="1781287"  //bytes
				callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is slow ${speed}`)}
				callbackFunctionOnNetworkTest={(speed)=>setwifiSpeed(speed)}
		/>

		<div className="pd-5">
			<Row>
				<Col md="4">	
					<div className="clientvideo_container">
						<div  className="video-avatar-container_receiver call-window">					
							{!callAccepted  && (													
									<div className="align-center">    
										<div className="userImage">
											<div><img src={userImage} width="250px" /></div>
											<div className="clientvideo-label"><span>Client Video</span></div>
										</div>
									</div>										
							)}
						
							{callAccepted && !callEnded && userVideo && myVideo && (
								<div className="align-center">
									<div id="video2" className="align-center" >								                                                           
										<canvas className="overlay_outer" style={{width:"1px",height: "1px",top: "1px",left: "1px"}}></canvas>					
										<video id="localVideo" playsInline ref={userVideo} autoPlay 
											className="video-active" style={{  opacity: `${userVdoStatus ? "1" : "0"}`}}/>  
									</div>					
								</div>
							)}
						</div>
					</div>
				</Col>
				<Col md="3" className="pdl-10">            
					<Row>
					  <Col md="12" className="host_videolayer">
						{stream ? (		
							<Row>
							  <Col md="9">
								<div style={{ textAlign: "center" }} id={callAccepted && !callEnded ? "video1" : "video3"}>             
								<div className="video-avatar-container">                                        
								  <video playsInline muted ref={myVideo} id="first_video" autoPlay className="video-active"
									style={{  opacity: `${myVdoStatus ? "1" : "0"}`}}/>
								</div>
								</div>
								</Col>
								<Col md="3">
								<div className="iconsDiv">
								  <div className="icons" onClick={() => {updateMic(); }} tabIndex="0" >
									<i className={`fa fa-microphone${myMicStatus ? "" : "-slash"}`}
									  style={{ transform: "scaleX(-1)" }}
									  aria-label={`${myMicStatus ? "mic on" : "mic off"}`}
									  aria-hidden="true" ></i>
								  </div>

								  <div className="icons" onClick={() => updateVideo()} tabIndex="0">
									{myVdoStatus ? (
									  <img src={VideoIcon} alt="video on icon" />
									) : ( <img src={VideoOff} alt="video on icon" /> )}
								  </div>								  
								</div>	
								
							  </Col>
							  </Row>
							) : (<div className="bouncing-loader">
								<div></div>
								<div></div>
								<div></div>
							  </div>
							)}
					</Col>

					{/*<Col md="12" className="bandwidth_layer align-center">
					  <div><b>Bandwidth Details :</b></div>
					  <div className="internetspeed">Host BandWidth:{internetspeed} MB</div>
					  <div className="internetspeed">Host BandWidth Updated On:{internetspeedlastupdated}</div>
					  <div className="internetspeed">Client BandWidth:{clientinternetspeed} MB</div>
					  <div className="internetspeed">Client BandWidth Updated On:{clientinternetspeedlastupdated}</div>
					</Col>*/}

					<Col md="12" className="instruction_layer align-center">
						<ProcessInstructions/>
					</Col>
				  </Row>            
			  </Col>

			  <Col md="5" className="pdl-10">      
			  <div className="row pd-10 pdt-0">        
				<div className="tabs_layer">
					<div className="box">
					  <div className="steps">
						<ul className="nav">
						  {steps.map((step, i) => {
							return <li key={i} className={`${activeStep.key === step.key ? 'active' : ''} ${step.isDone ? 'done' : ''}`}>
							  <div><span>{step.label}</span></div>
							</li>
						  })}
						</ul>
					  </div>
					<div className="step-component">
						<div className="btn-component align-right" style={{marginTop:'1px'}}>
							<Row className="width-100">
								<Col md="12" className="width-100 align-right">
								
								{skipBtn && (
									<input type="button" className="btn btn-danger float-left" value={'Skip'} onClick={handleNext} />		
								)}

								{/*<input type="button" className="btn btn-danger" value="Back" onClick={handleBack} disabled={steps[0].key === activeStep.key} />*/}
								<input type="button" style={{display:nextBtnDisplay}} className="btn btn-danger float-right" value={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Submit'} onClick={handleNext} />								
								</Col>
							</Row>
						</div>

				   {activeStep.component()}

				</div>
			  </div>
			</div>

				</div>
			  </Col>
			</Row>   
		 </div>
	 {/*} )} */}
        <ToastContainer />
  </div>
  );
};

export default HostVideo;
